function maskCep(value: string) {
  value = value.replace(/\D/g, "").replace(/^(\d{2})(\d{3})(\d{3})$/, "$1.$2-$3")
  return value;
}

function limparCEP(cep: string) {
  return cep.replace(/\D/g, ''); 
}

function maskCpf(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
  return value;
}

function maskCnpj(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  return value;
}

function maskCpfCnpj(value: string) {
  value = `${value}`.replace(/[^\d]/g, '');

  if (value.length <= 11) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else {
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
}


function maskPhone(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  return value;
}

function maskCurrency(value: string) {
  value = value.replace(/[^\d0]/g, "")
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  return value;
}

const maskBRL = (value: number): string => {
  const options = {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  };

  return value.toLocaleString('pt-BR', options);
}

function maskPrice(value: string) {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1.$2");
  return value;
}

function maskNumber(value: string) {
  value = value.replace(/\D/g, "").replace(/(\d{2})$/, "$1")
  return value;
}

export const maskMoney = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1.$2");
  return value;
}

const maskPhone2 = (value: string) => {
  if (value) {
    // Remove qualquer caractere não numérico
    value = value.replace(/\D/g, '');

    // Máscara de telefone fixo: (##) ####-####
    if (value.length <= 10) {
      value = value
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    }
    // Máscara de celular: (##) #####-####
    else {
      value = value
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2');
    }

    return value;

  } else {
    return ''
  }
};


export { maskCep, limparCEP, maskCpf, maskCnpj, maskPhone, maskCurrency, maskPrice, maskNumber, maskBRL, maskPhone2, maskCpfCnpj }