import { FaUser, FaHome, FaMoneyCheck } from 'react-icons/fa';
import './index.css'
import { StateFormFIlling } from '../../pages/Proposal';
import { LuFolderInput } from 'react-icons/lu';

type MenuProposalProps = {
    stateForm: number;
    handleStateForm: (stateForm: number) => void;
    handleFormState: StateFormFIlling
}

const MenuProposal = (props: MenuProposalProps) => {
    console.log(props.stateForm)
    return (
        <div className=" h-fit menu-container px-0 relative flex-row md:flex-col justify-between md:justify-start md:gap-10">

            <div className="absolute block md:hidden top-1/2 left-0 right-0 border-t-2 border-gray-200 z-0"></div>

            <div className="menu-item z-10" onClick={() => props.handleStateForm(0)}>
                <div className="menu-icon" style={{ backgroundColor: props.stateForm === 0 ? '#0E2245' : '#d3d3d3' }}>
                    <FaUser className="icon" style={{ color: props.stateForm === 0 ? '#fff' : '#555' }} />
                </div>
                <span className="menu-text hidden md:flex">Proponentes</span>
            </div>

            <div className="menu-item z-10" onClick={() => props.handleStateForm(1)}>
                <div className="menu-icon" style={{ backgroundColor: props.stateForm === 1 ? '#0E2245' : '#d3d3d3' }}>
                    <FaHome className="icon" style={{ color: props.stateForm === 1 ? '#fff' : '#555' }} />
                </div>
                <span className="menu-text hidden md:flex">Imóvel</span>
            </div>

            <div className="menu-item z-10" onClick={() => props.handleStateForm(2)}>
                <div className="menu-icon" style={{ backgroundColor: props.stateForm === 2 ? '#0E2245' : '#d3d3d3' }}>
                    <FaMoneyCheck className="icon" style={{ color: props.stateForm === 2 ? '#fff' : '#555' }} />
                </div>
                <span className="menu-text hidden md:flex">Pagamento</span>
            </div>

            <div className="menu-item z-10" onClick={() => props.handleStateForm(3)}>
                <div className="menu-icon" style={{ backgroundColor: props.stateForm === 3 ? '#0E2245' : '#d3d3d3' }}>
                    <LuFolderInput className="icon" style={{ color: props.stateForm === 3 ? '#fff' : '#555' }} />
                </div>
                <span className="menu-text hidden md:flex">Enviar</span>
            </div>
        </div>

    );
};

export default MenuProposal;
