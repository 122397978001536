import { Container } from "./styles"

interface IPropsCardsNewaLetter {
    title: string;
    subtitle: string;
    img: string;
}

const CardNewsLetter = ({title, subtitle, img} : IPropsCardsNewaLetter) =>  {
    return(
        <Container>
            <img alt="" src={img} />
            <div className="content-text">
                <p className="title">{title}</p>
                <p className="subtitle">{subtitle}</p>
            </div>
        </Container>
    )
}

export default CardNewsLetter