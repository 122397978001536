/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { Header } from "../../components/Header";
import { Container } from "../Home/styles";
import { ScrollProps } from "../../components/AdvancedSearch";
import MenuProposal from "../../components/MenuProposal";
import FormProposer, { FormSchemaProposer } from "../../components/FormProposer";
import { ConstructorOptions, FormSchemaConclusion, FormSchemaPayment, FormSchemaProperty, IProposalBackEnd, PaymentMethods, PropertyOptions } from "../../types";
import FormProperty from "../../components/FormProperty";
import { useAuth } from "../../context/auth";
import FormPayment from "../../components/FormPayment";
import './index.css';
import { IoMdArrowRoundBack } from "react-icons/io";
import FormConclusion from "../../components/FormConclusion";
import { Modal } from "antd";

export type StateFormFIlling = {
    formProposerFilled: boolean;
    formPropertyFilled: boolean;
    formPaymentFilled: boolean;
    formConclusionFilled: boolean;
}

type ResponseSubmit = {
    message: string[];
    code: number;
    title?: string;

}

export function Proposal() {

    const { api, user } = useAuth()

    const idCorretor = Number(user?.id_corretor)

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('');
    const [stateForm, setStateForm] = useState(0);
    const [proponentList, setProponentList] = useState<FormSchemaProposer[] | null>(null);

    const [propertyOptions, setPropertyOptions] = useState<PropertyOptions[]>([] as PropertyOptions[]);
    const [paymentOptions, setPaymentOptions] = useState<PaymentMethods[]>([] as PaymentMethods[]);
    const [companyOptions, setCompanyOptions] = useState<ConstructorOptions[]>([] as ConstructorOptions[]);

    const [property, setProperty] = useState<FormSchemaProperty | null>(null);

    const [paymentMethodsList, setPaymentMethodsList] = useState<FormSchemaPayment[] | null>(null);

    const [additionalData, setAdditionalData] = useState<FormSchemaConclusion | null>(null);
    const [allowSent, setAllowSent] = useState<boolean>(false);

    const [published, setPublished] = useState<boolean>(true);

    const [responseSubmit, setResponseSubmit] = useState<ResponseSubmit | null>(null);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const [handleFormState, setHandleFormState] = useState<StateFormFIlling>({
        formProposerFilled: false,
        formPropertyFilled: false,
        formPaymentFilled: false,
        formConclusionFilled: false
    });


    const handleStateForm = (obj: number) => {
        setStateForm(obj);
        window.scrollTo(0, 0);
    };

    const handleProposer = (obj: FormSchemaProposer, index?: number) => {

        if (index != null) {
            const updatedArray = proponentList?.map((item, i) => i === index ? obj : item);
            if (updatedArray) setProponentList(updatedArray);
        } else {
            setProponentList((prev) => prev ? [...prev, obj] : [obj]);
        }

        window.scrollTo(0, 0);
    };

    const delProposer = (index: number) => {
        const updateArray = proponentList?.filter((_, i) => i !== index);

        if (updateArray && updateArray.length > 0) {
            setProponentList(updateArray);
        } else {
            setProponentList(null);
        }

        window.scrollTo(0, 0);
    }

    const handleProperty = (obj: FormSchemaProperty) => {
        setProperty(obj);
        window.scrollTo(0, 0);
    };

    const delProperty = () => {
        setProperty(null);

        window.scrollTo(0, 0);
    }

    const delPayment = (index: number) => {
        const updateArray = paymentMethodsList?.filter((_, i) => i !== index);

        if (updateArray && updateArray.length > 0) {
            setPaymentMethodsList(updateArray);
        } else {
            setPaymentMethodsList(null);
        }
        window.scrollTo(0, 0);

    }

    const handlePayment = (obj: FormSchemaPayment, index?: number) => {

        if (index != null) {
            const updatedArray = paymentMethodsList?.map((item, i) => i === index ? obj : item);
            if (updatedArray) setPaymentMethodsList(updatedArray);
        } else {
            setPaymentMethodsList((prev) => prev ? [...prev, obj] : [obj]);
        }
        window.scrollTo(0, 0);
    }

    const dataFormSubmit: IProposalBackEnd = (useMemo(() => {
        // const count = 0;
        // Object.keys(handleFormState).forEach((key: any) => {
        //     console.log(`${key}, ${handleFormState[key]}`);
        // })

        setHandleFormState({
            formProposerFilled: proponentList ? true : false,
            formPropertyFilled: property ? true : false,
            formPaymentFilled: paymentMethodsList ? true : false,
            formConclusionFilled: additionalData ? true : false,
        })

        return {
            proponentes: proponentList ?? null,
            pagamentos: paymentMethodsList ?? null,
            imovel: property,
            //additionalData
            origem_lead: additionalData?.origem_lead ?? 0,
            tipo_proposta: additionalData?.tipo_proposta ?? 0,
            observacao: additionalData?.observacao ?? '',
            //dados mockados
            id_corretor: idCorretor,
            id_empresa: 1,
            comissao: 4,
            id_proposta: null,
            published,
        };
    }, [proponentList, property, paymentMethodsList, additionalData, published, idCorretor]));

    const handleBackForm = () => {
        if (stateForm === 3) {
            setStateForm(2);
        }
        if (stateForm === 2) {
            setStateForm(1);
        }
        if (stateForm === 1) {
            setStateForm(0);
        }
    }

    const handleAdditionalData = (obj: FormSchemaConclusion) => {
        setAdditionalData(obj);
        window.scrollTo(0, 0);
    }

    const handleSetAllowSent = (allowSent: boolean) => {
        setAllowSent(allowSent);
    }

    const delAdditionalData = () => {
        setAdditionalData(null);
        window.scrollTo(0, 0);
    }

    const info = () => {
        Modal.info({
            title: 'Dados incompletos',
            content: 'Preencha todos os formulários para prosseguir',
        });
    };

    const handlePublished = () => {
        setPublished(false);
    }

    const fetchData = async () => {

        await api.get('/sin/condpag')
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                } else {
                    console.log(res);
                    return Promise.reject('Erro ao carregar opções de pagamento');
                }
            })
            .then((data) => {
                setPaymentOptions(data);
            })
            .catch((error) => {
                console.error(error);
            });

        await api.get('/sin/constructors')
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                } else {
                    console.log(res);
                    return Promise.reject('Erro ao carregar construtoras');
                }
            })
            .then((data) => {
                setCompanyOptions(data);
            })
            .catch((error) => {
                console.error(error);
            });

        await api.get('/portal/building/list-building/0')
            .then((res) => {
                if (res.status === 200) {
                    return res.data;
                } else {
                    console.log(res);
                    return Promise.reject('Erro ao carregar propriedades');
                }
            })
            .then((data) => {
                setPropertyOptions(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const sentProposal = async () => {

        if (!proponentList || !property || !paymentMethodsList || !additionalData) {
            info();
            setAllowSent(false);
            return;
        }

        const sendForm = async () => {
            setResponseSubmit(null);
            setModalIsOpen(true);
            await api.post('/sin/proposta', dataFormSubmit)
                .then((response) => {
                    console.log('resapi', response.data);
                    setResponseSubmit(response.data.message);
                    setResponseSubmit({
                        message: response.data.message,
                        code: response.status
                    })
                })
                .catch((error) => {
                    console.log(error.response.data);

                    setResponseSubmit({
                        message: error.response.data.error,
                        code: error.response.status,
                        title: error.response.data.message
                    })
                })
                .finally(() => {
                    // setProponentList(null);
                    // setProperty(null);
                    // setPaymentMethodsList(null);
                    // setAdditionalData(null);
                    // setHandleFormState(null);
                    setAllowSent(false);
                });
        }
        sendForm();
    };


    useEffect(() => {
        if (allowSent) {
            sentProposal();
        }
    }, [allowSent]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>

            <Modal
                title='Envio da proposta'
                centered
                width={1000}
                open={modalIsOpen}
                onOk={() => { }}
                onCancel={() => { setModalIsOpen(false) }}
                footer={null}
                style={{ backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                <div className=" flex flex-col h-full w-full items-center justify-center text-primary text-2xl font-medium ">
                    <div className="w-1 h-full bg-primary"></div>
                    {responseSubmit?.code !== 200 && responseSubmit?.message?.map((item, index) => {
                        return (
                            <div key={index} className="flex flex-col justify-start items-start gap-y-3">
                                <h1 className="text-red-500 text-4xl self-center">Erro</h1>
                                {responseSubmit?.title && <h2 className="text-red-500">{responseSubmit?.title}</h2>}
                                <p>{index + 1} - {item}</p><br />
                            </div>
                        )
                    })}

                    {responseSubmit?.code === 200 &&
                        <div className="flex flex-col justify-start items-start gap-y-3">
                            <h1 className="text-green-500 text-2xl">Sucesso</h1>
                            <p>{responseSubmit?.message}</p><br />
                        </div>
                    }
                </div>
            </Modal>
            <Container className="" style={{ overflow: 'hidden' }}>
                <Header scroll={scrollTop} show={show} setShow={setShow} search={search} setSearch={setSearch} />
                <Container style={{ width: '85%', maxWidth: '1800px', height: '100%', marginLeft: 'auto', marginRight: 'auto', overflow: 'hidden' }}> {/*ContentWrapper */}
                    <h1 className="text-2xl my-6" >Nova Proposta</h1>


                    <div style={{ display: 'flex', width: '100% !important' }} className="divide-x gap-3 flex-col md:flex-row">
                        <MenuProposal stateForm={stateForm} handleFormState={handleFormState} handleStateForm={handleStateForm} />
                        <div>
                            <div className="flex justify-between items-center">
                                {stateForm > 0 && <h6 onClick={() => handleBackForm()} className="link-text flex w-fit items-center justify-start text-md text-dark px-6 pt-3"><IoMdArrowRoundBack /> Voltar</h6>}
                                <h6 className={`flex justify-end text-md text-dark ${stateForm === 0 ? 'w-full' : 'w-fit'} px-6 pt-3`}>{`Passo ${stateForm + 1}/4`}</h6>
                            </div>
                            <div className="form_proposal max-h-[60vh] min-w-[68vw] overflow-y-scroll p-6">
                                {stateForm === 0 &&
                                    <FormProposer
                                        handleStateForm={handleStateForm}
                                        handleProposer={handleProposer}
                                        proponentList={proponentList}
                                        delProposer={delProposer}
                                    />
                                }
                                {stateForm === 1 &&
                                    <FormProperty
                                        propertyOptions={propertyOptions}
                                        companyOptions={companyOptions}
                                        handleStateForm={handleStateForm}
                                        handleProperty={handleProperty}
                                        property={property}
                                        delProperty={delProperty}
                                    />
                                }
                                {stateForm === 2 &&
                                    <FormPayment
                                        paymentOptions={paymentOptions}
                                        paymentMethodsList={paymentMethodsList}
                                        handleStateForm={handleStateForm}
                                        handlePayment={handlePayment}
                                        delPayment={delPayment}
                                    />
                                }
                                {stateForm === 3 &&
                                    <FormConclusion
                                        handleAdditionalData={handleAdditionalData}
                                        setAllowSent={handleSetAllowSent}
                                        aditionalData={additionalData}
                                        delAdditionalData={delAdditionalData}
                                        setPublished={handlePublished}
                                    />
                                }

                            </div>
                        </div>
                    </div>

                </Container>


            </Container>
        </>)
}