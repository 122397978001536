import { Container } from "./styles";
import { IProximidades } from "../../types";
import { distance } from "../../utils/functions";
import { Tag } from 'antd'

interface Props {
    proximidade: IProximidades;
    coordenadasImovel: {
        lat: number;
        long: number;
    }
}

export function ProximityBadge({proximidade, coordenadasImovel}: Props) {
    return (
        <Container>
            <Tag title={proximidade.descricao}>
                <div className='color-highlight' style={{backgroundColor: proximidade.color}}>
                    <div className='icon-border'>
                        <img className="badge-icon" src={proximidade.url_icon} alt=''></img>  
                    </div>
                </div>
                <span className="badge-description">{proximidade.descricao}</span>
                <span className="badge-distance">{distance({ lat1: coordenadasImovel.lat, lon1: coordenadasImovel.long, lat2: Number(proximidade.latitude), lon2: Number(proximidade.longitude) })} km</span>
            </Tag>
        </Container>
    );
}