import { createContext, useCallback, useState, useContext, useMemo } from 'react'

import { IBuildindCorretor } from '../types';
import { useAuth } from './auth';


interface SearchAdvancedContextProps {
    handleSearchBuildingsAdvanced: () => void;
    buildingAdvanced: IBuildindCorretor[];
    buildingAdvancedTotal: IBuildindCorretor[];
    loadingAdvanced: boolean;
    loadingAdvancedPage: boolean;
    advancedSearchOperation: string[];
    setAdvancedSearchOperation: (val: string[]) => void;
    advancedOperationLacamento: boolean;
    setAdvancedOperationLancamento: (val: boolean) => void;
    advancedOperationVenda: boolean;
    setAdvancedOperationVenda: (val: boolean) => void;
    advancedOperationAluguel: boolean;
    setAdvancedOperationAluguel: (val: boolean) => void;
    rangerPriceSaleInitial: number;
    setRangerPriceSaleInitial: (val: number) => void;
    rangerPriceSaleFinal: number;
    setRangerPriceSaleFinal: (val: number) => void;
    rangerPriceRentInitial: number;
    setRangerPriceRentInitial: (val: number) => void;
    rangerPriceRentFinal: number;
    setRangerPriceRentFinal: (val: number) => void;
    comodoBedRooms: number;
    setComodoBedRooms: (val: number) => void;
    comodoBathrooms: number;
    setComodoBathrooms: (val: number) => void;
    comodoSuites: number;
    setComodoSuites: (val: number) => void;
    comodoVagas: number;
    setComodoVagas: (val: number) => void;
    cardsCategoriesSelect: number[];
    setCardsCategoriesSelect: (val: number[]) => void;
    district: string;
    setDistrict: (val: string) => void;
    city: string;
    setCity: (val: string) => void;
    state: string;
    setState: (val: string) => void;
    typeBuildsSelected: string[];
    setTypeBuildsSelected: (val: string[]) => void;
    scrollPagesAdvanced: () => void;
}

const SearchAdvancedContext = createContext<SearchAdvancedContextProps>({} as SearchAdvancedContextProps);

const SearchAdvancedProvider = ({ children }: any) => {

    const { user, api } = useAuth();

    const [loadingAdvanced, setLoadingAdvanced] = useState(false);
    const [loadingAdvancedPage, setLoadingAdvancedPage] = useState(false);

    const [advancedSearchOperation, setAdvancedSearchOperation] = useState<string[]>([])
    const [advancedOperationLacamento, setAdvancedOperationLancamento] = useState(true);
    const [advancedOperationVenda, setAdvancedOperationVenda] = useState(true);
    const [advancedOperationAluguel, setAdvancedOperationAluguel] = useState(true)
    const [rangerPriceSaleInitial, setRangerPriceSaleInitial] = useState(0);
    const [rangerPriceSaleFinal, setRangerPriceSaleFinal] = useState(0);
    const [rangerPriceRentInitial, setRangerPriceRentInitial] = useState(0);
    const [rangerPriceRentFinal, setRangerPriceRentFinal] = useState(0);
    const [comodoBedRooms, setComodoBedRooms] = useState(0);
    const [comodoBathrooms, setComodoBathrooms] = useState(0);
    const [comodoSuites, setComodoSuites] = useState(0);
    const [comodoVagas, setComodoVagas] = useState(0);
    const [cardsCategoriesSelect, setCardsCategoriesSelect] = useState<number[]>([]);
    const [typeBuildsSelected, setTypeBuildsSelected] = useState<string[]>([]);
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');

    const [page, setPage] = useState(0)
    const [fazerReq, setFazerReq] = useState(true)

    const [buildingAdvanced, setBuildingAdvanced] = useState<IBuildindCorretor[]>([])
    const [buildingAdvancedTotal, setBuildingAdvancedTotal] = useState<IBuildindCorretor[]>([])

    const categoriesEdit = useMemo(() => {
        let cat = ''
        cardsCategoriesSelect.forEach(c => {
            if (cat === '') {
                cat = `${c}`
            } else {
                cat = `${cat}, ${c}`
            }
        })
        return cat
    }, [cardsCategoriesSelect])


    const typeEdit = useMemo(() => {
        let type = '';
        typeBuildsSelected.forEach(t => {
            if (type === '') {
                type = t
            } else {
                type = `${type}, ${t}`
            }
        })
        return type
    }, [typeBuildsSelected])


    const handleSearchBuildingsAdvanced = useCallback(async () => {
        setLoadingAdvanced(true)
        setBuildingAdvanced([])
        setBuildingAdvancedTotal([])

        await api.get(`/portal/building/${user ? 'corret-' : ''}list-building/0?query_search&is_sale=${advancedSearchOperation.includes('1') ? '1' : ''}&min_sale_price=${rangerPriceSaleInitial}&max_sale_price=${rangerPriceSaleFinal}&is_rent=${advancedSearchOperation.includes('2') ? '1' : ''}&min_rent_price=${rangerPriceRentInitial}&max_rent_price=${rangerPriceRentFinal}&seller&constructor&no_address=1&id_category=${categoriesEdit}&is_highlight&id_corretor=${user ? user.id_corretor : ''}&is_launch=${advancedSearchOperation.includes('0') ? '1' : ''}&id_type=${typeEdit}&qtd_rooms=${`${comodoBedRooms}`}&qtd_bathrooms=${`${comodoBathrooms}`}&qtd_suite=${`${comodoSuites}`}&qtd_parking=${`${comodoVagas}`}&address_state=${state}&address_city=${city}&address_district=${district}`).then(res => {
            setBuildingAdvanced(res.data)
            setBuildingAdvancedTotal(res.data)
            setPage(1)
        }).catch(e => console.log(e)).finally(() => setLoadingAdvanced(false))

        setLoadingAdvanced(false)
    }, [api, user, advancedSearchOperation, rangerPriceSaleInitial, rangerPriceSaleFinal, rangerPriceRentInitial, rangerPriceRentFinal, categoriesEdit, typeEdit, comodoBedRooms, comodoBathrooms, comodoSuites, comodoVagas, state, city, district]);

    const handleSearchBuildingsAdvancedNextPage = useCallback(async () => {
        setLoadingAdvancedPage(true)

        await api.get(`/portal/building/${user ? 'corret-' : ''}list-building/${page}?query_search&is_sale=${advancedSearchOperation.includes('1') ? '1' : ''}&min_sale_price=${rangerPriceSaleInitial}&max_sale_price=${rangerPriceSaleFinal}&is_rent=${advancedSearchOperation.includes('2') ? '1' : ''}&min_rent_price=${rangerPriceRentInitial}&max_rent_price=${rangerPriceRentFinal}&seller&constructor&no_address=1&id_category=${categoriesEdit}&is_highlight&id_corretor=${user ? user.id_corretor : ''}&is_launch=${advancedSearchOperation.includes('0') ? '1' : ''}&id_type=${typeEdit}&qtd_rooms=${`${comodoBedRooms}`}&qtd_bathrooms=${`${comodoBathrooms}`}&qtd_suite=${`${comodoSuites}`}&qtd_parking=${`${comodoVagas}`}&address_state=${state}&address_city=${city}&address_district=${district}`).then(res => {
            if (res.data.lenght < 12) {
                setFazerReq(false)
            }
            setBuildingAdvanced(res.data)
            // console.log(res.data)
            setBuildingAdvancedTotal(prevBuildingAdvancedTotal => [...prevBuildingAdvancedTotal, ...res.data]);
        }).catch(e => console.log(e)).finally(() => setLoadingAdvancedPage(false))

        setLoadingAdvanced(false)
    }, [api, user, page, advancedSearchOperation, rangerPriceSaleInitial, rangerPriceSaleFinal, rangerPriceRentInitial, rangerPriceRentFinal, categoriesEdit, typeEdit, comodoBedRooms, comodoBathrooms, comodoSuites, comodoVagas, state, city, district]);

    const scrollPagesAdvanced = useCallback(() => {
        if (fazerReq) {
            if (page > 0 && buildingAdvanced.length > 0) {
                handleSearchBuildingsAdvancedNextPage();
                // console.log(page);
            }
            setPage((pageInsideState) => pageInsideState + 1)
        }

        // console.log(page)
    }, [fazerReq, page, buildingAdvanced.length, handleSearchBuildingsAdvancedNextPage])

    return (
        <SearchAdvancedContext.Provider value={{
            handleSearchBuildingsAdvanced,
            buildingAdvanced,
            loadingAdvanced,
            loadingAdvancedPage,
            advancedSearchOperation,
            setAdvancedSearchOperation,
            advancedOperationLacamento,
            setAdvancedOperationLancamento,
            advancedOperationVenda,
            setAdvancedOperationVenda,
            advancedOperationAluguel,
            setAdvancedOperationAluguel,
            rangerPriceSaleInitial,
            setRangerPriceSaleInitial,
            rangerPriceSaleFinal,
            setRangerPriceSaleFinal,
            rangerPriceRentInitial,
            setRangerPriceRentInitial,
            rangerPriceRentFinal,
            setRangerPriceRentFinal,
            comodoBedRooms,
            setComodoBedRooms,
            comodoBathrooms,
            setComodoBathrooms,
            comodoSuites,
            setComodoSuites,
            comodoVagas,
            setComodoVagas,
            cardsCategoriesSelect,
            setCardsCategoriesSelect,
            district,
            setDistrict,
            city,
            setCity,
            state,
            setState,
            typeBuildsSelected,
            setTypeBuildsSelected,
            buildingAdvancedTotal,
            scrollPagesAdvanced
        }}>
            {children}
        </SearchAdvancedContext.Provider>
    )
}

function useSearchAdvanced(): SearchAdvancedContextProps {
    const context = useContext(SearchAdvancedContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }

    return context
}

export { SearchAdvancedProvider, useSearchAdvanced };