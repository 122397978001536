import styled from 'styled-components';

export const Container = styled.div`
    max-width: 33.3%;
    width: 33.3%;
    padding: 10px;

    @media (max-width: 1200px) {
        max-width: 50%;
        width: 50%;
    }

    .ant-tag {
        max-width: 350px;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        flex-wrap: nowrap; /* Garante que os itens não quebrem linha */
        overflow: hidden; /* Esconde conteúdo que ultrapassa os limites */
        padding: 5px 10px 5px 0px;
    }

    .color-highlight {
        display: flex;
        height: 200%;
        justify-content: center;
        align-items: center;
        width: auto; /* Permite que a largura se ajuste */
        flex-shrink: 0; /* Garante que a área do ícone não diminua */
        padding: 0 10px; /* Define um espaço interno para cobrir a área até o texto */
    }

    .icon-border {
        display: flex; /* Flexbox para centralizar */
        justify-content: center; /* Centraliza horizontalmente */
        align-items: center; /* Centraliza verticalmente */
        width: 25px; 
        height: 25px;
        border-radius: 50%; /* Círculo */
        background-color: white;
        overflow: hidden;
        flex-shrink: 0;
    }

    .badge-icon {
        width: 50%;
        height: 50%;
        object-fit: cover;
    }

    .badge-description {
        font-size: 0.75rem;
        color: #333;
        font-weight: 600;
        flex-grow: 1; /* Permite que o texto ocupe o espaço disponível */
        word-wrap: break-word; /* Permite a quebra de palavras longas */
        word-break: break-word; /* Garante quebra de palavras longas */
        white-space: normal; /* Permite múltiplas linhas */
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

    }

    .badge-distance {
        font-size: 0.75rem;
        color: light-gray;
        font-weight: 500;
        text-align: right;
    }
`;