import { useCallback, useEffect, useState } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaUserCircle } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, ScrollProps } from '../../components/Header';
import { IBuildindCorretor, IPropsCorretor } from '../../types';
import { BsPatchCheckFill } from 'react-icons/bs';

import {
  Container,
  Content
} from './styles';

import { CardInfo } from '../../components/CardInfo';
import { Footer } from '../../components/Footer';
import { Button } from '../../components/Button';
import { MdLocalPhone } from 'react-icons/md';
import { maskPhone } from '../../utils/mask';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/auth';
import { LoadSpinner2 } from '../../components/LoadSpinner2';
import validator from 'validator';
import Carousel from 'react-multi-carousel';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { GoToTop } from '../../GoToTop';


const MySwal = withReactContent(Swal)


export function Member() {

  const { id } = useParams()

  const { user, api } = useAuth()

  const [obs, setObs] = useState('');
  const [nomeCliente, setNomeCliente] = useState('');
  const [emailCliente, setEmailCliente] = useState('');
  const [contatoCliente1, setContatoCliente1] = useState('');

  const [corretor, setCorretor] = useState<IPropsCorretor>({} as IPropsCorretor)
  // const [categorys, setCategorys] = useState<ICategorys[]>([])
  const [categorysExists, setCategorysExists] = useState<string[]>([])

  const loadData = useCallback(async () => {
    await api.get(`/portal/corretor/${id}`).then(res => {
      setCorretor(res.data)
    })
  }, [id, api])

  const loadCategorys = useCallback(async () => {
    await api.get('/portal/category').then(res => {
      // setCategorys(res.data)
      // console.log('categorys', res.data)
    })
  }, [api])

  useEffect(() => {
    loadData()
    loadCategorys()
  }, [loadData, loadCategorys])

  const navigate = useNavigate();
  const [building, setBuilding] = useState<IBuildindCorretor[]>([]);
  const [buildingDestaque, setBuildingDestaque] = useState<IBuildindCorretor[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);
  const [loading, setLoading] = useState(false)
  const [loadingContact, setLoadingContact] = useState(false);

  const loadBuildings = useCallback(() => {
    setLoading(true);

    api.get(`portal/building/${user && user.id_corretor ? 'corret-': ''}list-building/0?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller=${id}&constructor&no_address=1&id_category&is_highlight&id_corretor=${user && user.id_corretor?id:''}`)
      .then((res) => {
        setBuilding(res.data);
        // console.log(res.data)
      })
      .catch(e => console.log(e.response.data.message))
      .finally(() => setLoading(false));
  }, [api, user, id]);

  const loadBuildingsDestaque = useCallback(() => {
    setLoading(true)
    api.get(`portal/building/${user && user.id_corretor ? 'corret-': ''}list-building/0?query_search&is_sale&min_sale_price&max_sale_price&is_rent&min_rent_price&max_rent_price&seller=${id}&constructor&no_address=1&id_category&is_highlight=1&id_corretor=${user && user.id_corretor?id:''}`).then(res => {
      setBuildingDestaque(res.data)
    }).catch(e => console.log(e)).finally(() => setLoading(false))
  }, [api, id, user]);

  useEffect(() => {
    loadBuildings();
    loadBuildingsDestaque();
  }, [loadBuildings, loadBuildingsDestaque]);

  useEffect(() => {
    navigator.geolocation.watchPosition(position => {
      const { latitude, longitude } = position.coords;
      setLat(latitude ? latitude : 0);
      setLong(longitude ? longitude : 0);
    });
  }, [])

  const handleContact = useCallback(async () => {
    if (nomeCliente === '') {
      return toast.error('Digite o seu nome', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (contatoCliente1 === '') {
      return toast.error('Digite o número do seu contato', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (emailCliente === '') {
      return toast.error('Digite seu endereço de e-mail', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (!validator.isEmail(emailCliente)) {
      return toast.error('Digite um e-mail válido', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    if (obs === '') {
      return toast.error('Digite a mensagem de contato', {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
    }

    setLoadingContact(true)
    MySwal.fire({
      title: 'Aguarde',
      text: 'Estamos salvando as informações',
      icon: 'warning',
      didOpen: () => {
        MySwal.showLoading()
      }
    })
    await api.post('/portal/seller/contact', {
      id_corretor: user.id_corretor,
      message: obs,
      client_name: nomeCliente,
      client_email: emailCliente,
      client_phone1: contatoCliente1,
      client_phone2: 'não informado'
    }).then(res => {
      setObs('')
      setNomeCliente('')
      setEmailCliente('')
      setContatoCliente1('')
      MySwal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'O processo foi realizado. Em breve seu contato será retornado',
        confirmButtonColor: '#0E2244'
      })
    }).catch(e => {
      console.log(e)
      MySwal.fire({
        icon: 'error',
        title: 'Ops!',
        text: 'Não foi possível realizar o seu contato.',
        confirmButtonColor: '#0E2244',
        footer: `${e.response.data.err}`
      })
    }).finally(() => setLoadingContact(false))
  }, [nomeCliente, contatoCliente1, emailCliente, user, obs, api])

  const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
  }

  // const buildFix: IBuildindCorretor[] = useMemo(() => {
  //   return building.filter(b => b.is_fixado === 1)
  // }, [building])

  useEffect(() => {
    const buildFix = building.filter(b => b.is_fixado === 1)
    buildFix.forEach((bul) => {
      `${bul?.categorias}`.split(',').forEach(b => {
        if (!categorysExists.includes(b)) {
          setCategorysExists([...categorysExists, b])
        }
      })
    })
  })

  return (
    <Container onScroll={handleScroll}>
      <Header show={show} setShow={setShow} scroll={scrollTop} search={search} setSearch={setSearch} />
      {
        loading &&
        <LoadSpinner2 />
      }
      {
        !loading &&
        <>
          <Content show={show}>
            <div className='header'>
              <img className='placeholder-header' src={corretor?.banner_empresa ? corretor?.banner_empresa : 'https://portal-nogueira.s3.us-east-2.amazonaws.com/cd42e655ed0cb744de4e-placeholder-header-corretor-default.jpg'} alt="background header corretor" />
              {
                corretor?.banner_empresa === '' &&
                <img className='logo-white' src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/489f6642413ff70b4204-logo-hub-nogueira-white.png'} alt="logo hub nogueira branco" />
              }
            </div>
            <div className='body'>
              {
                corretor?.foto_perfil ?
                  <img src={corretor?.foto_perfil} className='avatar' alt='avatar' />
                  :
                  <img src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} className='avatar' alt='avatar' />
              }
              <div className='title-and-others'>
                <div className='title-and-infos'>
                  <p className='name-corretor'>{corretor?.nome}</p>
                  <p className='class'>Especialista Nogueira <BsPatchCheckFill className='check-corretor' /></p>
                  <p className='creci'>CRECI {corretor?.creci}</p>
                </div>
                <div className='icons-social-midias'>
                  {
                    (corretor?.instagram !== null && corretor?.instagram !== '' && corretor?.instagram !== " ") &&
                    <a href={`https://${corretor?.instagram}`} target='_blank' rel='noreferrer'><FaInstagram className='icons-midias' /></a>
                  }
                  {
                    (corretor?.facebook !== null && corretor?.facebook !== '' && corretor?.facebook !== " ") &&
                    <a href={`https://${corretor?.facebook}`} target='_blank' rel='noreferrer'><FaFacebookF className='icons-midias' /></a>
                  }
                  {
                    (corretor?.linkedin !== null && corretor?.linkedin !== '' && corretor?.linkedin !== " ") &&
                    <a href={`https://${corretor?.linkedin}`} target='_blank' rel='noreferrer'><FaLinkedinIn className='icons-midias' /></a>
                  }
                </div>
              </div>
            </div>
            <div className='traco' />

            <div className='conten-cards-imoveis-destaque' style={{ marginBottom: '20px' }}>
              <h3>Destaques</h3>
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={5000}
                centerMode={false}
                className="conten-cards-imoveis-destaque-carrosel"
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={true}
                renderButtonGroupOutside={true}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass="slide"
                slidesToSlide={1}
                swipeable
              >
                {buildingDestaque && buildingDestaque.map((item) => {
                  return (
                    <div className="imoveis">
                      <CardInfo
                        key={item.id}
                        id={item.id}
                        descricao={item.descricao}
                        latitude={item.latitude}
                        longitude={item.longitude}
                        valor_aluguel={item.valor_aluguel}
                        valor_venda={item.valor_venda}
                        is_venda={item.is_venda}
                        is_aluguel={item.is_aluguel}
                        periodo_aluguel={item.periodo_aluguel}
                        arquivos={item.arquivos}
                        lat={lat}
                        long={long}
                        onClick={() => navigate(`/perfil/${id}/imovel/${item.id}`)}
                        idBuilding={item.id}
                        is_fixado={item.is_fixado}
                        is_destaque={item.is_destaque}
                        is_lancamento={item.is_lancamento}
                        bairro={item.bairro}
                        cidade={item.cidade}
                        marginRight='10px'
                        marginLeft='0px'
                      />
                    </div>
                    // </SplideSlide>
                  )
                })
                }
              </Carousel>

              {/* </Splide> */}
            </div>

            <div className='content-cards-and-contact'>
              <div className='left'>
                <div className='conten-cards-imoveis'>

                  <div className="imoveis">
                    {building && building.map(fix => (

                      <CardInfo
                        key={fix.id}
                        id={fix.id}
                        descricao={fix.descricao}
                        latitude={fix.latitude}
                        longitude={fix.longitude}
                        valor_aluguel={fix.valor_aluguel}
                        valor_venda={fix.valor_venda}
                        is_venda={fix.is_venda}
                        is_aluguel={fix.is_aluguel}
                        periodo_aluguel={fix.periodo_aluguel}
                        arquivos={fix.arquivos}
                        lat={lat}
                        long={long}
                        onClick={() => navigate(`/perfil/${id}/imovel/${fix.id}`)}
                        idBuilding={fix.id}
                        is_fixado={fix.is_fixado}
                        is_destaque={fix.is_destaque}
                        is_lancamento={fix.is_lancamento}
                        bairro={fix.bairro}
                        cidade={fix.cidade}
                        marginRight='10px'
                      />

                    ))}
                  </div>
                  {/* </Carousel> */}
                  {/* </>
                      )
                    })
                  } */}
                  {/* <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay={true}
                autoPlaySpeed={3000}
                centerMode={false}
                className="conten-cards-imoveis"
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 3,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass="slide"
                slidesToSlide={1}
                swipeable
              >
                {building && building.map((item, indx) => {
                  return (
                    
                    <div className="imoveis">
                      <CardInfo
                        key={item.id}
                        id={item.id}
                        descricao={item.descricao}
                        latitude={item.latitude}
                        longitude={item.longitude}
                        valor_aluguel={item.valor_aluguel}
                        valor_venda={item.valor_venda}
                        is_venda={item.is_venda}
                        is_aluguel={item.is_aluguel}
                        periodo_aluguel={item.periodo_aluguel}
                        arquivos={item.arquivos}
                        lat={lat}
                        long={long}
                        onClick={() => navigate(`/imovel/${item.id}`)}
                        idBuilding={item.id}
                        is_fixado={item.is_fixado}
                        is_destaque={item.is_destaque}
                      />
                    </div>
                   
                  )
                })
                }
                
              </Carousel> */}
                </div>
              </div>
              {/*========================================*/}
              <div className='right'>
                <div className="desc-right-wrapper">

                  <div className="announcement">
                    <div className="broker-information">
                      <div className="image" onClick={() => navigate(`/perfil/${id}`)}>{corretor?.foto_perfil ? <img src={corretor?.foto_perfil} alt='avatar' /> : <FaUserCircle className='avatar-no-logged' />}</div>
                      <div className="name">
                        <h3 onClick={() => navigate(`/perfil/${id}`)}>{corretor?.nome}</h3>
                        <p>{corretor?.email_social}</p>
                        <p className='phone'><MdLocalPhone className='avatar-phone' />{maskPhone(corretor?.telefone_01 ? corretor?.telefone_01 : '')}</p>
                      </div>
                    </div>
                  </div>

                  <h3>Entre em contato</h3>
                  <div className="form">

                    <input disabled={loadingContact} type="text" value={nomeCliente} onChange={(e) => setNomeCliente(e.target.value)} placeholder='Nome Completo' />
                    <input disabled={loadingContact} type="text" maxLength={11} value={maskPhone(contatoCliente1)} onChange={(e) => setContatoCliente1(e.target.value)} placeholder='DDD + Telefone' />
                    {/* <input type="text" maxLength={11} value={maskPhone(contatoCliente2)} onChange={(e) => setContatoCliente2(e.target.value)} placeholder='Telefone 2' /> */}
                    <input disabled={loadingContact} type="email" value={emailCliente} onChange={(e) => setEmailCliente(e.target.value)} placeholder='Digite seu e-mail' />
                    {/* <input type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} placeholder='Nome do Imóvel *obrigatório' /> */}
                    <textarea disabled={loadingContact} value={obs} onChange={(e) => setObs(e.target.value)} placeholder='Mensagem'></textarea>

                    {/* <h4>Selecione uma data para visita:</h4>
                    <input type="date" />
                    <Calendar
                      className='calendar'
                      minDate={new Date()}
                      value={dateReserve}
                      onChange={(v, e) => setDateReserve(v)}
                    />
                    <h4>Seleciona a hora:</h4>
                    <input type="time" /> */}

                    <Button
                      label={loadingContact ? 'Enviando' : 'Enviar'}
                      width='100%'
                      height='50px'
                      color='#fff'
                      bgcolor='#0F2445'
                      onclick={handleContact}
                      mb='1rem'
                      disable={loadingContact}
                    />

                  </div>
                </div>
              </div>
            </div>
          </Content>

          <GoToTop />
          <Footer />
        </>
      }

    </Container >
  );
}