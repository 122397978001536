import { IDistance } from "../types";

export const distance = ({ lat1, lon1, lat2, lon2 }: IDistance, formatSize = 2) => {
    const earthRadius = 6371; // raio da Terra em km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
        + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2))
        * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance: any = earthRadius * c; // distância em km
    distance = Number(distance.toFixed(formatSize));
    distance = distance.toLocaleString('pt-BR');
    return distance;
}

export const toRadians = (degrees: number) => {
    return degrees * Math.PI / 180;
}

export const inverterCor = (cor: string) => {
    // Remove o "#" no início, se presente
    cor = cor.replace(/^#/, "");

    // Converter para formato RGB se necessário
    if (cor.length === 3) {
        cor = cor.split("").map(c => c + c).join("");
    }

    // Calcular o inverso
    const match = cor.match(/.{2}/g);
    const invertedColor = match ? match.map(component => (255 - parseInt(component, 16)).toString(16).padStart(2, "0")).join("") : "";

    return `#${invertedColor}`;
}
