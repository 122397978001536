import { useState, useCallback } from 'react';

import {
  Container,
} from './styles';

interface IPropCategorie {
  label: string;
  // eslint-disable-next-line no-empty-pattern
  setCheck: ([]) => void;
  checked: number[];
  id: number;
}

export function CardCategorie({ label, checked, setCheck, id }: IPropCategorie){
  const [selected, setSelected] = useState(false);

  const handlesSelect = useCallback(() => {
    if(checked.includes(id)) {
      setSelected(false);
      setCheck(checked.filter(c => c !== id)) 
    } else {
      setSelected(true);
      setCheck([...checked, id])
    }
  },[id, setCheck, setSelected, checked])

  return(
    <Container>
      <div 
        className={selected ? 'cat-single clicked' : 'cat-single'} 
        onClick={handlesSelect}
      >{label}</div>
    </Container>
    );
  }