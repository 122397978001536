import { MdCancel } from 'react-icons/md';
import { IArquivo } from '../../types';
import { Container } from "./styles";
import '@splidejs/splide/dist/css/splide.min.css';
import { Carousel } from 'antd';

interface IProps {
  isOpen?: boolean;
  file?: string;
  onClose: () => void;
  files?: IArquivo[];
  index?: number;
}

export function ShowPhotoLargue({ onClose, files, index }: IProps) {
  // console.log(files)

  return (
    <Container >
      <div className="div-img">

        <button className="button-close" onClick={onClose}><MdCancel color="white" size={30} /></button>

        <Carousel arrows infinite dots={false} className="carousel-imovel"  initialSlide={index} draggable>
          {files?.map((images, index) => (
            <div key={index}>
              <div className="div-imagem">
                <img src={images.url} alt="imagem" className="img-style" />
              </div>
            </div>
          ))}
        </Carousel>
        
      </div>

    </Container >
  );
}


