import { Divider, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { limparCEP, maskCpfCnpj, maskPhone2 } from "../../utils/mask";
import { maskMoney } from "../../utils/mask";
import axios from "axios";
import { maskCep } from "../../utils/mask";
import './index.css'
import { MdDelete, MdOutlineAddBox, MdSkipNext } from "react-icons/md";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import './index.css'

type FormProposerProps = {
    handleStateForm: (obj: number) => void;
    handleProposer: (obj: FormSchemaProposer, index?: number) => void;
    proponentList: FormSchemaProposer[] | null;
    delProposer: (index: number) => void;
}

const EstadoCivilInputs = ['1', '2', '3', '4', '5', '6'];

const validationSchema = z.object({
    nome: z
        .string()
        .min(1, "O nome de usuário é obrigatorio."),

    cpfcnpj: z
        .string({
            required_error: 'CPF/CNPJ é obrigatório.',
        })
        .refine((doc) => {
            const replacedDoc = doc.replace(/\D/g, '');
            return replacedDoc.length >= 11;
        }, 'CPF/CNPJ inválido.')
        .refine((doc) => {
            const replacedDoc = doc.replace(/\D/g, '');
            return replacedDoc.length <= 14;
        }, 'CPF/CNPJ deve conter no máximo 14 caracteres.')
        .refine((doc) => {
            const replacedDoc = doc.replace(/\D/g, '');
            return !!Number(replacedDoc);
        }, 'CPF/CNPJ deve conter apenas números.'),

    datanascimento: z
        .string()
        .refine((value) => value.trim() !== "", {
            message: "A data de nascimento é obrigatória.",
        })
        .refine((value) => new Date(value) < new Date(), {
            message: "A data de nascimento deve ser no passado.",
        }),

    rg: z
        .string()
        .min(1, "O RG é obrigatório."),

    naturalidade: z
        .string()
        .min(1, "A naturalidade é obrigatória."),

    nacionalidade: z
        .string()
        .min(1, "A nacionalidade é obrigatória."),

    telefone: z
        .string()
        .min(1, "O telefone é obrigatório."),

    email: z
        .string()
        .min(1, "O email é obrigatório."),

    estadocivil: z
        .string()
        .refine((data) => EstadoCivilInputs.includes(data), {
            message: "Selecione uma opção válida.",
        })
        .transform((val) => parseInt(val, 10)),

    profissao: z
        .string()
        .min(1, "A Profissão é obrigatória."),

    renda_mensal: z
        .string()
        .min(1, "A renda mensal é obrigatória."),

    observacao: z.string(),

    cep: z
        .string()
        .min(1, "O cep é obrigatório."),

    codibge: z
        .string()
        .min(1, "O Código IBGE é obrigatório."),

    numero: z
        .string()
        .min(1, "O número da residência é obrigatório."),

    logradouro: z
        .string()
        .min(1, "A rua é obrigatória."),

    bairro: z
        .string()
        .min(1, "O bairro é obrigatório."),

    cidade: z
        .string()
        .min(1, "A Cidade é obrigatória."),

    estado: z
        .string()
        .min(1, "Campo obrigatório."),

    complemento: z
        .string()
        .min(1, "O complemento é obrigatório."),

    origem_lead: z
        .number(),

    pais: z
        .string()


});

export type FormSchemaProposer = z.infer<typeof validationSchema>;
const FormProposer = (props: FormProposerProps) => {

    const [isFormFilled, setIsFormFilled] = useState(false);

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [indexEdit, setIndexEdit] = useState<number | null>(null);

    const [valueToCep, setValueToCep] = useState<string>('');
    const [showCodeibge, setShowCodeibge] = useState<boolean>(false);

    const idEstadoCivilOptions = [
        { value: 1, label: "Solteiro(a)" },
        { value: 2, label: "Casado(a)" },
        { value: 3, label: "Divorciado(a)" },
        { value: 4, label: "Viúvo(a)" },
        { value: 5, label: "Separado(a)" },
        { value: 6, label: "União Estável" },
    ]

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm<FormSchemaProposer>({
        resolver: zodResolver(validationSchema),
        defaultValues: {
            origem_lead: 0,
            pais: 'Brasil',
        }
    });

    const onSubmit = async (data: FormSchemaProposer) => {
        //setIsFormFilled(true);

        if (isEditMode && indexEdit != null) {
            props.handleProposer(data, indexEdit);

        } else {
            props.handleProposer(data);
        }

        // props.handleStateForm(1);
        reset();
        setIndexEdit(null);
        setIsEditMode(false);
    }

    const handleCpfCnpjChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedCpf = maskCpfCnpj(e.target.value);
        setValue("cpfcnpj", maskedCpf);
    };

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedPhone = maskPhone2(e.target.value);
        setValue("telefone", maskedPhone);
    };

    const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValueToCep(maskCep(e.target.value));
        setValue("cep", maskCep(e.target.value));
    };

    const handlemonthlyIncomeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedPhone = maskMoney(e.target.value);
        setValue("renda_mensal", maskedPhone); // Atualiza o valor da renda mensal com a máscara aplicada
    };

    const addProposer = () => {
        setIndexEdit(null);
        setIsEditMode(false);
        setIsFormFilled(false);
        reset();
    }

    const handleEditProposer = (index: number) => {
        // console.log("props: ", props.proponentList)
        if (props.proponentList) {
            reset(props.proponentList[index]);
        }
        setIndexEdit(index);
        setIsEditMode(true);
        // setValue("estadocivil", 1)
        setIsFormFilled(false);
    }

    const deleteProposal = (index: number) => {

        if (props.proponentList) props.delProposer(index);
    }
    const infoNotFoundCep = () => {
        Modal.info({
            title: 'Cep não encontrado',
            content: 'Não foi possível encontrar o CEP informado, informe manualmente o seu endereço',
        });
    };



    useEffect(() => {
        if (props.proponentList) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }
    }, [props.proponentList])

    useEffect(() => {

        const consultarCep = async (cep: string) => {
            setShowCodeibge(false);
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                .then((response) => {
                    return response;
                })
                .catch(() => {
                    infoNotFoundCep();
                    setShowCodeibge(true);
                })

            if (response && !response.data.erro) {
                setValue("cep", response.data.cep);
                setValue("logradouro", response.data.logradouro);
                setValue("bairro", response.data.bairro);
                setValue("cidade", response.data.localidade);
                setValue("estado", response.data.uf);
                setValue("codibge", response.data.ibge);

            } else {
                infoNotFoundCep();
                setShowCodeibge(true);
            }
        }

        if (valueToCep) {
            if (valueToCep?.replace(/\D/g, '').length === 8) {
                consultarCep(limparCEP(valueToCep));
            }
        }
    }, [valueToCep, setValue]);
    //console.log(props.proponentList);
    return (
        <>
            <h3 className="text-xl font-semibold">Cadastro de proponentes</h3>

            <Divider type="horizontal" />
            <div className="flex flex-col gap-5 w-full">

                <h4 className="text-lg mb-3">Proponentes cadastrados</h4>
                {props.proponentList && props.proponentList.length > 0 ? (
                    <div className="space-y-4">
                        {props.proponentList.map((proponente, index) => (
                            <div key={index} className={`flex items-center justify-between border rounded-md p-4 border-gray-20 `}>
                                <div>
                                    <h5 className="font-semibold">{proponente.nome}</h5>
                                    <p className="text-sm text-gray-600">CPF: {proponente.cpfcnpj}</p>
                                    <p className="text-sm text-gray-600">Telefone: {proponente.telefone}</p>
                                    <p className="text-sm text-gray-600">Renda: R$ {proponente.renda_mensal}</p>
                                </div>
                                <div className="flex gap-3">
                                    <button onClick={() => handleEditProposer(index)} className="text-blue-500"><FaEdit size={25} /></button>
                                    <button onClick={() => deleteProposal(index)} className="text-red-500"><MdDelete size={25}/></button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex items-center justify-center gap-1">
                        <p className="text-lg font-light text-info text-gray-400 italic">Você ainda não possui proponentes cadastrados</p>
                    </div>
                )}
            </div>

            <Divider type="horizontal" />

            {/* Formulário de cadastro */}
            {
                isFormFilled ? (
                    <div className="row">
                        <div className="form-group col-6 flex justify-between">
                            <div
                                className={`w-fit py-2 px-4 gap-1 rounded-md bg-green-whatsapp flex justify-center items-center hover:cursor-pointer`}
                                onClick={() => addProposer()}
                            >
                                <MdOutlineAddBox />
                                <span className="text-xs lg:text-base xl:text-lg text-link ">Adicionar Proponente</span>
                            </div>
                            <div
                                className={`w-fit py-2 gap-1 rounded-md flex justify-center items-center hover:cursor-pointer`}
                                onClick={() => props.handleStateForm(1)}
                            >

                                <span className="bg-[#0E2245] px-5 flex items-center py-3 whitespace-nowrap w-fit rounded-lg text-white"><MdSkipNext size={20} /> Próximo</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="">

                        <h3 className="text-xl font-semibold mb-2">Dados Pessoais</h3>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <div className="grid grid-cols-12 gap-4 items-end w-full">

                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label className="text-base" htmlFor="nome">Nome</label>
                                    <input
                                        id="nome"
                                        type="text"
                                        {...register("nome")}
                                        placeholder="Nome / Razão Social"
                                        className="border border-gray-300 shadow p-3 w-full rounded-md"
                                    />
                                    {errors.nome && <span className="text-red-500">{errors.nome.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="cpfcnpj" className="text-base">CPF/CNPJ</label>
                                    <input
                                        id="cpfcnpj"
                                        placeholder="CPF ou CNPJ do proponente"
                                        type="text"
                                        {...register("cpfcnpj")}
                                        onChange={handleCpfCnpjChange}
                                        className="border border-gray-300 shadow p-3 w-full rounded-md"
                                    />
                                    {errors.cpfcnpj && <span className="text-red-500">{errors.cpfcnpj.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="datanascimento" className="text-base">Data de nascimento</label>
                                    <input
                                        id="datanascimento"
                                        {...register("datanascimento")}
                                        type="date"
                                        className="border border-gray-300 shadow p-3 w-full rounded-md max-h-[49.6px]"
                                    />
                                    {errors.datanascimento && <span className="text-red-500 text-sm">{errors.datanascimento.message}</span>}
                                </div>



                            </div>



                            <div className="grid grid-cols-12 gap-4 items-end w-full my-2">
                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="rg" className="text-base">RG</label>
                                    <input
                                        id="rg"
                                        {...register("rg")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.rg && <span className="text-red-500 text-sm">{errors.rg.message}</span>}
                                </div>
                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="naturalidade" className="text-base">Naturalidade</label>
                                    <input
                                        id="naturalidade"
                                        type="text"
                                        {...register("naturalidade")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.naturalidade && <span className="text-red-500 text-sm">{errors.naturalidade.message}</span>}
                                </div>
                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="nacionalidade" className="text-base">Nacionalidade</label>
                                    <input
                                        id="nacionalidade"
                                        type="text"
                                        {...register("nacionalidade")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.nacionalidade && <span className="text-red-500 text-sm">{errors.nacionalidade.message}</span>}
                                </div>
                            </div>

                            <Divider type="horizontal" dashed />

                            <h3 className="text-xl font-semibold mt-6">Dados de Contato</h3>

                            <div className="grid grid-cols-12 gap-4 items-end w-full my-2">
                                <div className="form-group col-span-12 sm:col-span-6 lg:col-span-6">
                                    <label htmlFor="telefone" className="text-base">Telefone</label>
                                    <input placeholder="(00) 00000-0000"
                                        id="telefone"
                                        {...register("telefone")}
                                        onChange={handlePhoneChange}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.telefone && <span className="text-red-500">{errors.telefone.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-6 lg:col-span-6">
                                    <label htmlFor="email" className="text-base">E-mail</label>
                                    <input
                                        id="email"
                                        {...register("email")}
                                        placeholder="exemplo@email.com.br"
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.email && <span className="text-red-500">{errors.email.message}</span>}
                                </div>
                            </div>

                            <Divider type="horizontal" dashed />

                            <h3 className="text-xl font-semibold my-6">Outras Informações</h3>


                            <div className="grid grid-cols-12 gap-4 items-end w-full my-2">
                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="estadocivil" className="block text-base ">Estado Civil</label>
                                    <select
                                        id="estadocivil"
                                        {...register("estadocivil")}
                                        className="mt-1 h-[50px] block w-full px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                        defaultValue={-1}
                                    >
                                        <option value={-1}>Selecione</option>
                                        {idEstadoCivilOptions.map((option) => (
                                            <option key={option?.value} value={option?.value}>
                                                {option?.label}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.estadocivil && <span className="mt-2 text-sm text-red-500">{errors.estadocivil.message}</span>}
                                </div>



                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="profissao" className="text-base">Profissão</label>
                                    <input
                                        id="profissao"
                                        type="text"
                                        {...register("profissao")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.profissao && <span className="text-red-500 text-sm">{errors.profissao.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                    <label htmlFor="renda_mensal" className="">Renda Mensal</label>
                                    <input
                                        id="renda_mensal"
                                        placeholder="R$0,00"
                                        {...register("renda_mensal")}
                                        onChange={handlemonthlyIncomeChange}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg money-input"
                                    />
                                    {errors.renda_mensal && <span className="text-red-500 text-sm">{errors.renda_mensal.message}</span>}
                                </div>

                            </div>

                            <div className="row">
                                <div className="form-group col-lg-12 mb-6">
                                    <label htmlFor="observacao" className="">Observações</label>
                                    <textarea
                                        id="observacao"
                                        {...register("observacao")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                        rows={4}
                                    />
                                </div>
                            </div>

                            <Divider type="horizontal" dashed />

                            <h3 className="text-xl font-semibold mt-6">Endereço</h3>

                            <div className="grid grid-cols-12 gap-4 items-baseline w-full my-2">

                                <div className="form-group col-span-12 sm:col-span-5 lg:col-span-5">
                                    <label htmlFor="cep" className="text-base">CEP</label>
                                    <input
                                        type="text"
                                        id="cep"
                                        {...register("cep")}
                                        placeholder="00000-000"
                                        onChange={handleCepChange}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.cep && <span className="text-red-500 text-sm">{errors.cep.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-5 lg:col-span-5">
                                    <label htmlFor="bairro" className="text-base">Bairro</label>
                                    <input
                                        id="bairro"
                                        type="text"
                                        {...register("bairro")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.bairro && <span className="text-red-500 text-sm">{errors.bairro.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-2 lg:col-span-2">
                                    <label htmlFor="numero" className="text-base">Número</label>
                                    <input
                                        id="numero"
                                        type="text"
                                        {...register("numero")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.numero && <span className="text-red-500 text-xs">{errors.numero.message}</span>}
                                </div>
                            </div>

                            {showCodeibge && <div className="grid grid-cols-12 gap-4 items-end w-full my-2">
                                <div className="form-group col-span-12 sm:col-span-6 lg:col-span-6">
                                    <label htmlFor="codibge" className="text-base">Código IBGE</label>
                                    <input
                                        id="codibge"
                                        type="text"
                                        {...register("codibge")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                </div>
                            </div>}

                            <div className="grid grid-cols-12 gap-4 items-baseline w-full my-2">

                                <div className="form-group col-span-12 sm:col-span-5 lg:col-span-5">
                                    <label htmlFor="logradouro" className="text-base">Logradouro</label>
                                    <input
                                        id="logradouro"
                                        type="text"
                                        {...register("logradouro")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.logradouro && <span className="text-red-500 text-sm">{errors.logradouro.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-5 lg:col-span-5">
                                    <label htmlFor="cidade" className="text-base">Cidade</label>
                                    <input
                                        id="cidade"
                                        type="text"
                                        {...register("cidade")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.cidade && <span className="text-red-500 text-sm">{errors.cidade.message}</span>}
                                </div>

                                <div className="form-group col-span-12 sm:col-span-2 lg:col-span-2">
                                    <label htmlFor="estado" className="text-base">Estado</label>
                                    <input
                                        id="estado"
                                        type="text"
                                        {...register("estado")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.estado && <span className="text-red-500 text-sm">{errors.estado.message}</span>}
                                </div>
                            </div>


                            <div className="row">
                                <div className="form-group col-lg-12">
                                    <label htmlFor="complemento" className="text-base">Complemento</label>
                                    <input
                                        id="complemento"
                                        type="text"
                                        {...register("complemento")}
                                        className="border border-gray-300 shadow p-3 w-full rounded-lg"
                                    />
                                    {errors.complemento && <span className="text-red-500 text-sm">{errors.complemento.message}</span>}
                                </div>
                            </div>
                            <div className="flex justify-end my-3">
                                <div className="form-group col-4">
                                    <button
                                        title="Próximo"
                                        type="submit"
                                        className="bg-green-500 px-5 py-3 w-fit rounded-lg text-white"
                                    >Salvar</button>
                                </div>
                            </div>
                        </form>
                    </div>)
            }
        </>
    )
}

export default FormProposer;