import { zodResolver } from "@hookform/resolvers/zod";
import { Divider, Radio, Space } from "antd";
import type { RadioChangeEvent } from 'antd';
import { useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import { z } from "zod";

type LeadProps = {
    value: string;
    option: string;
}

type NegociationProps = {
    value: string;
    option: string;
}
const validationSchema = (validLeadOptions: string[], validTypeProposalOptions: string[]) => z.object({

    origem_lead: z
        .string()
        .refine((data) => validLeadOptions.includes(data), {
            message: "Selecione uma opção válida.",
        })
        .transform((val) => parseInt(val, 10)),

    tipo_proposta: z
        .string()
        .refine((data) => validTypeProposalOptions.includes(data), {
            message: "Selecione uma opção válida.",
        })
        .transform((val) => parseInt(val, 10)),

    observacao: z
        .string(),

});
export interface FormSchemaConclusion {
    origem_lead: number;
    tipo_proposta: number;
    observacao: string;

}

type FormConclusionProps = {
    handleAdditionalData: (obj: FormSchemaConclusion) => void;
    setAllowSent: (value: boolean) => void;
    aditionalData: FormSchemaConclusion | null;
    delAdditionalData: () => void;
    setPublished: () => void;
}
const FormConclusion = (props: FormConclusionProps) => {


    const typeNegociation: NegociationProps[] = [
        { value: '1', option: 'Apartada' },
        { value: '2', option: 'Faturada' },
        { value: '3', option: 'Mista' }
    ]

    const typeLead: LeadProps[] = [
        { value: '2', option: 'Prospecção' },
        { value: '3', option: 'WhatsApp' },
        { value: '4', option: 'Indicação - Agente' },
        { value: '6', option: 'Anúncios' },
        { value: '13', option: 'Ligação Recebida' },
        { value: '19', option: 'Indicação do Cliente' },
        { value: '20', option: 'Lead' },
        { value: '21', option: 'WhatsApp Due' },
        { value: '22', option: 'Carteira' }
    ]

    const typeLeadOptions = typeLead.map((item: LeadProps) => {
        return item.value;
    })

    const typeNegociationOptions = typeNegociation.map((item: NegociationProps) => {
        return item.value;
    })

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm<FormSchemaConclusion>({
        resolver: zodResolver(validationSchema(typeLeadOptions, typeNegociationOptions)),
        // defaultValues: {
        //     published: true,
        //   }
    });

    const onChange = (e: RadioChangeEvent) => {

        const str = e.target.value.trim();

        // Divide a string em um array de palavras
        const words = str.split(' ');
        setValue('tipo_proposta', words[0]);
    };

    const onSubmit = async (data: FormSchemaConclusion) => {


        props.handleAdditionalData(data);
        reset();
    }

    const getLabelProposal = (value: number) => {
        const result = typeNegociation.find((item) => parseInt(item.value) === value
        )

        if (result) {
            return result.option;
        }
    }

    const getLabelLead = (value: number) => {
        const result = typeLead.find((item) => parseInt(item.value) === value
        )

        if (result) {
            return result.option;
        }
    }

    return (
        <>

            <h3 className="text-xl "> Enviar Proposta</h3>

            <Divider type="horizontal" />
            {props.aditionalData === null ?
                <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>

                    <div className="row">
                        <Radio.Group onChange={onChange}>
                            <Space direction="vertical">
                                {typeNegociation.map((item: NegociationProps, index) => (
                                    <Radio key={index} value={item.value}>{item.option}</Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                        {errors.tipo_proposta && <span className="text-red-500 text-sm">{errors.tipo_proposta.message}</span>}
                    </div>

                    <div className="row mt-2">
                        <div className="form-group col-lg-9">
                            <label htmlFor="origem_lead">Origem do Lead</label>
                            <select
                                id="origem_lead"
                                {...register("origem_lead")}
                                defaultValue="selecione"
                                className="border border-gray-300 shadow p-3 w-full rounded-md mt-1"
                            >
                                <option value="selecione" disabled>Selecione</option>
                                {typeLead.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.option}
                                    </option>
                                ))}
                            </select>
                            {errors.origem_lead && <span className="text-red-500 text-sm">{errors.origem_lead.message}</span>}
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="form-group col-lg-9">
                            <label htmlFor="observacao">Observações</label>
                            <textarea
                                id="observacao"
                                {...register("observacao")}
                                placeholder="Observações sobre a proposta"
                                className="border border-gray-300 shadow p-3 w-full rounded-lg mt-1"
                                rows={3}
                            />
                            {errors.observacao && <span className="text-red-500 text-sm">{errors.observacao.message}</span>}
                        </div>
                    </div>

                    <div className="flex justify-end my-3">
                        <div className="form-group col-4">
                            <button
                                title="Próximo"
                                type="submit"
                                className="bg-green-500 px-5 py-3 w-fit rounded-lg text-white"
                            >Enviar Proposta</button>
                        </div>
                    </div>

                </form>
                :
                <div className="row flex flex-col gap-y-5">
                    <p className="text-info font-medium">Informação adicional cadastrada</p>

                    <div className="form-group col-lg-4">
                        <div className="flex flex-col shadow-lg p-5 gap-y-2 rounded-md border-b-2 border-t-2 border-info bg-background">
                            <h3 className="text-lg "> Tipo proposta:  </h3>
                            <strong className="text-info">{getLabelProposal(props.aditionalData?.tipo_proposta)}</strong>
                            <span>Origem do Lead:</span>
                            <strong className="text-info"> {getLabelLead(props.aditionalData?.origem_lead)}</strong>
                            <span>Observação: </span>
                            <strong className="text-info"> {props.aditionalData?.observacao} </strong>
                        </div>
                        <div className="p-2 rounded flex items-center justify-center bg-delete hover:cursor-pointer" onClick={() => props.delAdditionalData()}>
                            <MdDelete size={20} color="red" />
                        </div>
                    </div>


                </div>

            }

        </>
    )
}
export default FormConclusion;