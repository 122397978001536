import { useState } from "react";
import { Header, ScrollProps } from "../../components/Header";
import { 
    Container,
    Content
} from "./style";
import { CardBlog } from "../../components/CardBlog";
import { Footer } from "../../components/Footer";
import { GoToTop } from "../../GoToTop";

export function ListBlog() {

    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('');

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    return (
        <Container onScroll={handleScroll}>
            <Header scroll={scrollTop} show={show} setShow={setShow} search={search} setSearch={setSearch} />
            <Content>
                <p className='title'>NOTÍCIAS DO MERCADO IMOBILIÁRIO</p>
                
                <div className="content-list">
                    <CardBlog />
                    <CardBlog />
                    <CardBlog />
                    <CardBlog />
                    <CardBlog />
                    <CardBlog />
                    <CardBlog />
                    <CardBlog />
                </div>
            </Content>
            <Footer />
            <GoToTop />
        </Container>
    )
}