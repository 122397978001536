'use client';
import { Divider } from "antd";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { maskMoney } from "../../utils/mask";
import { ConstructorOptions, PropertyOptions } from "../../types";
import { MdDelete, MdSkipNext } from "react-icons/md";

type FormPropertyProps = {
    propertyOptions: PropertyOptions[];
    companyOptions: ConstructorOptions[];
    handleStateForm: (obj: number) => void;
    handleProperty: (obj: FormSchemaProperty) => void;
    property: FormSchemaProperty | null;
    delProperty: () => void;
}

const validationSchema = (validOptionsProperty: string[], validOptionsCompany: string[]) => z.object({

    descricao_imovel: z
        .enum(validOptionsProperty as [string, ...string[]], {
            errorMap: () => ({ message: "Selecione uma opção válida." }),
        }),

    id_construtora: z
        .string()
        .refine((data) => validOptionsCompany.includes(data), {
            message: "Selecione uma opção válida.",
        })
        .transform((val) => {
            return parseInt(val, 10)
        }),

    unidade: z
        .string()
        .min(1, "A unidade é obrigatória."),

    bloco: z
        .string()
        .min(1, "O bloco é obrigatório."),

    valor: z
        .string()
        .min(1, "O valor é obrigatório.")

});

export interface FormSchemaProperty {
    descricao_imovel: string;
    id_construtora: number;
    unidade: string;
    bloco: string;
    valor: string;
}

const FormProperty = (props: FormPropertyProps) => {

    //const [isformFilled, setIsFormFilled] = useState(false);

    const propOptions = useMemo(() => {

        return props.propertyOptions.map((item: PropertyOptions) => {
            return {
                label: item?.descricao,
                value: item?.id
            }
        })
    }, [props.propertyOptions]);

    const typePropOptions = useMemo(() => {
        return props.propertyOptions.map((item: PropertyOptions,) => {
            return item?.descricao
        })
    }, [props.propertyOptions]);

    const constOptions = useMemo(() => {
        return props.companyOptions.map((item: ConstructorOptions) => {
            return {
                label: item?.nome,
                value: item?.construtora_id
            }
        })
    }, [props.companyOptions]);

    const typeConstOptions = useMemo(() => {
        return props.companyOptions.map((item: ConstructorOptions) => {
            return item?.construtora_id.toString()
        })
    }, [props.companyOptions]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        //reset,
    } = useForm<FormSchemaProperty>({
        resolver: zodResolver(validationSchema(typePropOptions, typeConstOptions)),
        // defaultValues: {
        //     optionMarital: "1",
        //   }
    });



    const onSubmit = async (data: FormSchemaProperty) => {
        //setIsFormFilled(true);
        props.handleProperty(data);
        // props.handleStateForm(2);
        //reset();
    }

    const handlePropertyValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedPropertyValue = maskMoney(e.target.value);
        setValue("valor", maskedPropertyValue);
    };

    const getLabelCompany = (value: number) => {
        const result = constOptions?.find((item) => item?.value === value);
        if (result) {
            return result?.label;
        }
    }
    return (
        <>
            <h3 className="text-xl font-semibold">Cadastro de imóvel</h3>

            <Divider type="horizontal" />
            {props.property != null ?
                <div className="row flex flex-col gap-y-3">
                    <p className="mb-3 text-lg">Imóvel cadastrado</p>

                    <div className="form-group col-lg-6">
                        <div key={props.property?.id_construtora} className={`flex items-center justify-between border rounded-md p-4 border-gray-20`}>
                            <div>
                                <h5 className="font-semibold">Imóvel: {props.property?.id_construtora} - {props.property?.descricao_imovel ? props.property?.descricao_imovel : 'Sem descrição'}</h5>
                                <p className="text-sm text-gray-600">Construtora: <strong className="text-info">{getLabelCompany(props.property?.id_construtora)}</strong></p>
                                <p className="text-sm text-gray-600">Valor: <strong className="text-info">R$ {props.property?.valor}</strong></p>
                                <div className="flex gap-x-2">
                                    <span>Bloco: <strong className="text-info">{props.property?.bloco}</strong></span>
                                    <span>Unidade: <strong className="text-info">{props.property?.unidade}</strong></span>
                                </div>
                            </div>
                            <div className="flex gap-3">
                                <button onClick={() => props.delProperty()} className="text-red-500 flex"><MdDelete size={25}/></button>
                            </div>
                        </div>
                    </div>

                    <Divider type="horizontal" />

                    <div className="row justify-end">
                        <div className="flex justify-end">
                            <div
                                className={`w-fit gap-1 rounded-md flex justify-center items-center hover:cursor-pointer`}
                                onClick={() => props.handleStateForm(2)}
                            >

                                <span className="bg-[#0E2245] px-5 flex items-center py-3 whitespace-nowrap w-fit rounded-lg text-white"><MdSkipNext size={20} /> Próximo</span>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <h3 className="text-xl font-semibold my-3">Dados do imóvel</h3>
                    <form className="row flex flex-col gap-y-5" onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="form-group col-lg-12">
                                <label htmlFor="descricao_imovel">Imóvel</label>
                                <select
                                    id="descricao_imovel"
                                    className="mt-1 h-[50px] block w-full px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                    defaultValue="selecione"
                                    //disabled={isformFilled}
                                    {...register("descricao_imovel")}
                                >
                                    <option value="selecione" disabled>Selecione</option>
                                    {propOptions?.map((option) => (
                                        <option key={option?.value} value={option?.label} >
                                            {option?.value + " - " + (option?.label ? option?.label : 'Descrição Não Informada')}
                                        </option>
                                    ))}
                                </select>
                                {errors.descricao_imovel && <span className="text-red-500 text-sm">{errors.descricao_imovel.message}</span>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-lg-12">
                                <label htmlFor="id_construtora">Construtora</label>
                                <select
                                    id="id_construtora"
                                    {...register("id_construtora")}
                                    className="mt-1 h-[50px] block w-full px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                    defaultValue={-1}>
                                    <option value={-1} disabled>Selecione</option>
                                    {constOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {errors.id_construtora && <span className="text-red-500 text-sm">{errors.id_construtora.message}</span>}
                            </div>
                        </div>

                        <div className="grid grid-cols-12 gap-4 items-baseline w-full">
                            <div className="form-group col-span-12 sm:col-span-3 lg:col-span-3">
                                <label htmlFor="unidade" className="text-base text-end">Unidade</label>
                                <input
                                    id="unidade"
                                    {...register("unidade")}
                                    className="mt-1 h-[50px] block w-full px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                />
                                {errors.unidade && <span className="text-red-500 text-xs">{errors.unidade.message}</span>}
                            </div>

                            <div className="form-group col-span-12 sm:col-span-4 lg:col-span-4">
                                <label htmlFor="bloco" className="text-base">Bloco</label>
                                <input
                                    id="bloco"
                                    {...register("bloco")}
                                    className="mt-1 h-[50px] block w-full px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                />
                                {errors.bloco && <span className="text-red-500 text-xs">{errors.bloco.message}</span>}
                            </div>

                            <div className="form-group col-span-12 sm:col-span-5 lg:col-span-5">
                                <label className="text-base text-end">Valor do Imóvel</label>
                                <input
                                    id="valor"
                                    {...register("valor")}
                                    onChange={handlePropertyValueChange}
                                    className="mt-1 h-[50px] block w-full px-4 py-2 border border-gray-300 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                                    placeholder="Valor R$"
                                />
                                {errors.valor && <span className="text-red-500 text-xs">{errors.valor.message}</span>}
                            </div>
                        </div>

                        <div className="flex justify-end my-3">
                            <div className="form-group col-4">
                                <button
                                    title="Próximo"
                                    type="submit"
                                    className="bg-green-500 px-5 py-3 w-fit rounded-lg text-white"
                                >Salvar</button>
                            </div>
                        </div>
                    </form>
                </div>


            }
        </>
    )
}

export default FormProperty;