import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2% 4%;
  background-color: #fff;
  z-index: 9999999999999999999999;
  overflow-y: auto;
  /* padding-bottom: 4rem; */

  .header {
    max-width: 35px;
    max-height: 35px;
    border-radius: 100%;
    background-color: rgba(0,0,0, .080);
    transition: all .2s ease;
    cursor: pointer;
    
    &:hover {
      background-color: rgba(0,0,0, .100);
    }
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0, .3) transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 3px; /* A altura só é vista quando a rolagem é horizontal */
  }

  &::-webkit-scrollbar-track {
    background: rgba(0,0,0, .1);
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0, .3);
  }
  
  .all-photos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(8, 1fr); */
    width: 100%;
    /* height: 100%; */
    gap: .5rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
    border-radius: 1rem;
    /* border: 1px solid red; */
  }
  
  .all-photos .img {
    border-radius: 1rem;
    background-color: rgba(0,0,0, .1);

    img {
      width: 100%;
      height: 500px;
      border-radius: 1rem;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .mbottom {
    margin-top: 4rem;
  }

  @media screen and (max-width: 1100px) {
    .header {
      margin-top: 2rem;
    }
    .all-photos {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 760px) {
    .all-photos {
      grid-template-columns: 1fr;
    }
  }

`;