import { RoutesApp } from './routes/index';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './styles/global';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import 'react-multi-carousel/lib/styles.css';
import 'devextreme/dist/css/dx.light.css';
import AppProvider from './context';
import { ConfigProvider } from 'antd';



function App() {
  return (
    <BrowserRouter basename='/'>
      <AppProvider>
        <GoogleOAuthProvider clientId="731161939030-k15eddt5mp9fn90jefeae5ckah2vgalc.apps.googleusercontent.com">
          <ToastContainer style={{ zIndex: '200000000000' }} />
          <GlobalStyles />
          <ConfigProvider
            theme={{
              components: {
                Carousel: {
                  arrowSize: 200,
                }
              }
            }}
          >
          </ConfigProvider>
          <RoutesApp />
        </GoogleOAuthProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
