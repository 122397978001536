import { Divider } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { maskMoney } from "../../utils/mask";
import { PaymentMethods } from "../../types";
import { MdDelete, MdOutlineAddBox, MdSkipNext } from "react-icons/md";
import './index.css'


type FormPaymentProps = {
    paymentOptions: PaymentMethods[];
    finishForm?: () => void;
    handleStateForm: (obj: number) => void;
    handlePayment: (obj: FormSchemaPayment, index?: number) => void;
    paymentMethodsList: FormSchemaPayment[] | null;
    delPayment: (index: number) => void;
}

const validationSchema = (validPaymentOptions: string[]) => z.object({

    id_formapagcondpag: z
        .string()
        .refine((data) => validPaymentOptions.includes(data), {
            message: "Selecione uma forma de pagamento",
        })
        .transform((val) => parseInt(val, 10)),

    qtd_parcelas: z.preprocess((val) => {
        if (typeof val === "string" && val.trim() === "") {
            return undefined; // Considera campos vazios como inválidos
        }
        return val;
    }, z.coerce.number({
        invalid_type_error: "O valor da parcela precisa ser um número válido.",
    })
        .int("O valor da parcela deve ser um número inteiro.")
        .gt(0, "O valor da parcela deve ser maior que zero.")),

    valor: z
        .string()
        .min(1, "O valor do pagamento é obrigatório."),

});

export interface FormSchemaPayment {
    id_formapagcondpag: number;
    qtd_parcelas: number;
    valor: string;

}
const FormPayment = (props: FormPaymentProps) => {

    const [indexEdit, setIndexEdit] = useState<number | null>(null);
    const [isFormFilled, setIsFormFilled] = useState(false);

    const pagOptions = useMemo(() => {
        return props.paymentOptions.map((item: PaymentMethods) => {
            return {
                label: item?.description,
                value: item?.id
            }
        })
    }, [props.paymentOptions]);

    const typePaymentOptions = useMemo(() => {
        return props.paymentOptions.map((item: PaymentMethods,) => {
            return item?.id.toString();
        })
    }, [props.paymentOptions]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset,
    } = useForm<FormSchemaPayment>({
        resolver: zodResolver(validationSchema(typePaymentOptions)),
        // defaultValues: {
        //     optionMarital: "1",
        //   }
    });

    const onSubmit = async (data: FormSchemaPayment) => {

        if (indexEdit != null) {
            props.handlePayment(data, indexEdit);
        } else {
            props.handlePayment(data);
        }
        // props.handleStateForm(3);
        reset();
    }



    const handleValuePaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const maskedPropertyValue = maskMoney(e.target.value);
        setValue("valor", maskedPropertyValue);
    };

    // const handleEditProposer = (index: number) => {
    //     if (props.paymentMethodsList) {
    //         reset({
    //             ...props.paymentMethodsList[index],
    //         });
    //     }
    //     setIndexEdit(index);
    // }


    const deleteProposal = (index: number) => {

        if (props.paymentMethodsList) props.delPayment(index);
    }

    const getLabelPaymentMethod = (codPayment: number) => {
        if (props.paymentMethodsList) {
            const result = pagOptions.find((item) =>
                item.value === codPayment
            )

            if (result) {
                return result.label;
            }
        }

    }

    const addPayment = () => {
        setIndexEdit(null);
        // setIsEditMode(false);
        setIsFormFilled(false);
        reset();
    }

    useEffect(() => {
        if (props.paymentMethodsList) {
            setIsFormFilled(true);
        } else {
            setIsFormFilled(false);
        }
    }, [props.paymentMethodsList])



    return (
        <>
            <h3 className="text-xl font-semibold">Cadastro de pagamentos</h3>

            <Divider type="horizontal" />

            <div className="w-full flex flex-col gap-3 overflow-x-auto scrollbar-thin ">
                <h3 className="mb-3">Métodos de Pagamentos Cadastrados</h3>
                {
                    props.paymentMethodsList ?
                        props.paymentMethodsList.map((method, index) => (
                            <div key={index} className={`flex items-center justify-between border rounded-md p-4 border-gray-20 w-full`}>
                                <div>
                                    <h5 className="font-semibold">{getLabelPaymentMethod(method.id_formapagcondpag)}</h5>
                                    <p className="text-sm text-gray-600">Valor: R${method.valor}</p>
                                    <p className="text-sm text-gray-600">{method.qtd_parcelas}x</p>
                                </div>
                                <div className="flex gap-3 items-end justify-end">
                                    <button onClick={() => deleteProposal(index)} className="text-red-500"><MdDelete size={25}/></button>
                                </div>
                            </div>
                        ))
                        :
                        <div className="flex items-center justify-center gap-1">
                            <p className="text-lg font-light text-info text-gray-400 italic">Você ainda não possui proponentes cadastrados</p>
                        </div>
                }
            </div>

            <Divider type="horizontal" />

            {isFormFilled ? (

                <div className="row">
                    <div className="form-group col-6 flex justify-between">
                        <div
                            className={`w-fit py-2 px-4 gap-1 rounded-md flex justify-center items-center hover:cursor-pointer`}
                            onClick={() => addPayment()}
                        >
                            <MdOutlineAddBox size={20} />
                            <span className="text-xs lg:text-base xl:text-lg text-link ">Adicionar Pagamento</span>
                        </div>
                        <div
                            className={`w-fit py-2 gap-1 rounded-md flex justify-center items-center hover:cursor-pointer`}
                            onClick={() => props.handleStateForm(3)}
                        >
                            
                            <span className="bg-[#0E2245] px-5 flex items-center py-3 whitespace-nowrap w-fit rounded-lg text-white"><MdSkipNext size={20} /> Próximo</span>
                        </div>
                    </div>
                </div>

            ) : (

                <div>
                    <h3 className="text-xl font-semibold my-3">Detalhes do pagamento</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="grid grid-cols-12 gap-4 items-baseline w-full my-3">
                            <div className="form-group col-span-12 sm:col-span-6 lg:col-span-6">
                                <label
                                    htmlFor="valor"
                                    className="text-base text-end"
                                >
                                    Valor do Pagamento
                                </label>
                                <input
                                    id="valor"
                                    {...register("valor")}
                                    className="border border-gray-300 shadow p-3 w-full rounded-md"
                                    placeholder="Valor R$"
                                    onChange={handleValuePaymentChange}
                                />
                                {errors.valor && <span className="text-red-500 text-sm">{errors.valor.message}</span>}
                            </div>

                            <div className="form-group col-span-12 sm:col-span-6 lg:col-span-6">
                                <label htmlFor="qtd_parcelas" className="text-base">Parcelas</label>
                                <input
                                    id="qtd_parcelas"
                                    {...register("qtd_parcelas")}
                                    className="border border-gray-300 shadow p-3 w-full rounded-md"


                                />
                                {errors.qtd_parcelas && <span className="text-red-500 text-sm">{errors.qtd_parcelas.message}</span>}
                            </div>
                        </div>

                        <div className="row mb-6">
                            <div className="form-group col-md-6 col-lg-3">
                                <label htmlFor="id_formapagcondpag">Forma de pagamento</label>
                                <select
                                    id="id_formapagcondpag"
                                    {...register("id_formapagcondpag")}
                                    className="border border-gray-300 shadow p-3 w-full rounded-md"
                                    defaultValue={-1}
                                >
                                    <option value={-1}>Selecione</option>
                                    {pagOptions.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {errors.id_formapagcondpag && <span className="text-red-500 text-sm">{errors.id_formapagcondpag.message}</span>}
                            </div>
                        </div>

                        <div className="flex justify-end">
                            <div className="form-group col-4">
                                <button
                                    title="Próximo"
                                    type="submit"
                                    className="bg-green-500 px-5 py-3 w-fit rounded-lg text-white"
                                >Salvar</button>
                            </div>
                        </div>

                    </form>
                </div>
            )}
        </>
    );
};

export default FormPayment;