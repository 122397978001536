/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { ConfigProvider, Select, Slider } from 'antd';
import '@splidejs/splide/dist/css/splide.min.css';

import {
  ContentModal,
} from './styles';
import { useAuth } from '../../context/auth';
import { GoToTop } from '../../GoToTop';
import { CardCategorie } from '../CardCategorie';
import { maskBRL } from '../../utils/mask';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { useSearchAdvanced } from '../../context/searchAdvanced';


export interface ScrollProps {
  y: number;
  lastY: number;
}


interface AdvSearchProps {

  show: boolean;
}

export function AdvancedSearch({ show }: AdvSearchProps) {

  // const navigate = useNavigate();

  // const [searchMobile, setSearchMobile] = useState(false);
  // const [modal2Open, setModal2Open] = useState(false);

  const [address, setAddress] = useState<any>();

  // const [modalLogin, setModalLogin] = useState(false)

  const {
    // user,
    categorys,
    typeBuild
  } = useAuth();


  const typeOptions = typeBuild && typeBuild.map(type => {
    return { value: type.id, label: type.titulo }
  })

  // const {
  //   // setCategorySelected,
  //   // setOperationSelected,
  //   // handleSearchBuildings
  // } = useSearch();

  const {
    advancedOperationAluguel,
    advancedOperationLacamento,
    advancedOperationVenda,
    advancedSearchOperation,
    cardsCategoriesSelect,
    comodoBathrooms,
    comodoBedRooms,
    comodoSuites,
    comodoVagas,
    rangerPriceRentFinal,
    rangerPriceRentInitial,
    rangerPriceSaleFinal,
    rangerPriceSaleInitial,
    setAdvancedOperationAluguel,
    setAdvancedOperationLancamento,
    setAdvancedOperationVenda,
    setAdvancedSearchOperation,
    setCardsCategoriesSelect,
    setState,
    setCity,
    setDistrict,
    setComodoBathrooms,
    setComodoBedRooms,
    setComodoSuites,
    setComodoVagas,
    setRangerPriceRentFinal,
    setRangerPriceRentInitial,
    setRangerPriceSaleFinal,
    setRangerPriceSaleInitial,
    setTypeBuildsSelected,
    // handleSearchBuildingsAdvanced,
  } = useSearchAdvanced()

  // useEffect(() => {
  //   if (user) {
  //     setModalLogin(false)
  //   }
  // }, [user])


  // const handleSearchFunction = useCallback(() => {
  //   handleSearchBuildings()
  //   if (window.location.href !== `${process.env.REACT_APP_URL_PORTAL}/resultado-pesquisa`) {
  //     navigate(`/resultado-pesquisa`)
  //   }
  //   setSearchMobile(false)
  // }, [handleSearchBuildings, navigate])

  // const handleSearchAdvancedFunction = useCallback(() => {
  //   handleSearchBuildingsAdvanced()
  //   setModal2Open(!modal2Open)
  //   if (window.location.href !== `${process.env.REACT_APP_URL_PORTAL}/resultado-pesquisa`) {
  //     navigate(`/resultado-pesquisa`)
  //   }
  //   setSearchMobile(false)
  // }, [handleSearchBuildingsAdvanced, navigate, modal2Open])


  // function onSearch(e: any) {
  //   if (e.key === 'Enter') {
  //     handleSearchFunction()
  //   }
  // }

  const handleChangeSaleInicial = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceSaleInitial(inputValue);
  };

  const handleChangeSaleFinal = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceSaleFinal(inputValue);
  };

  const handleChangeRentInicial = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceRentInitial(inputValue);
  };

  const handleChangeRentFinal = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = Number(event.target.value.replace(/\D/g, '')) / 100;
    setRangerPriceRentFinal(inputValue);
  };


  // const handleChangeOperations = (value: string[]) => {
  //   setOperationSelected(value)
  // }

  // const handleChangeCategorys = (value: string) => {
  //   setCategorySelected(value)
  // }

  const handleChangeTypeBuilds = (value: string[]) => {
    setTypeBuildsSelected(value)
  }

  useEffect(() => {
    if (address) {
      geocodeByAddress(address.label).then(res => {
        if (res[0]?.address_components?.length === 7) {
          setDistrict(res[0]?.address_components[2]?.long_name)
          setCity(res[0]?.address_components[3]?.long_name)
          setState(res[0]?.address_components[4]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 6) {
          setDistrict(res[0]?.address_components[1]?.long_name)
          setCity(res[0]?.address_components[2]?.long_name)
          setState(res[0]?.address_components[3]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 4 && res[0]?.address_components[0]?.types.length === 3) {
          setDistrict(res[0]?.address_components[0]?.long_name)
          setCity(res[0]?.address_components[1]?.long_name)
          setState(res[0]?.address_components[2]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 4 && res[0]?.address_components[0]?.types.length === 2) {
          setDistrict('')
          setCity(res[0]?.address_components[1]?.long_name)
          setState(res[0]?.address_components[2]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 2) {
          setDistrict('')
          setCity('')
          setState(res[0]?.address_components[0]?.short_name)
          return
        }
        if (res[0]?.address_components?.length === 1) {
          setDistrict('')
          setCity('')
          setState('')
          return
        }
        setAddress([])

      })
    }
  }, [address, setState, setCity, setDistrict])

  const handleAdvancedLancamento = useCallback((value: boolean) => {
    setAdvancedOperationLancamento(value)
    if (advancedOperationLacamento) {
      advancedSearchOperation.push('0')
      return
    }

    if (!advancedOperationLacamento) {
      let arr = []
      arr = advancedSearchOperation.filter(a => a !== '0')
      setAdvancedSearchOperation(arr)
      return
    }
  }, [advancedOperationLacamento, advancedSearchOperation, setAdvancedSearchOperation, setAdvancedOperationLancamento])

  const handleAdvancedVenda = useCallback((value: boolean) => {
    setAdvancedOperationVenda(value)
    if (advancedOperationVenda) {
      advancedSearchOperation.push('1')
      return
    }

    if (!advancedOperationVenda) {
      let arr = []
      arr = advancedSearchOperation.filter(a => a !== '1')
      setAdvancedSearchOperation(arr)
      return
    }
  }, [advancedOperationVenda, advancedSearchOperation, setAdvancedSearchOperation, setAdvancedOperationVenda])

  const handleAdvancedAluguel = useCallback((value: boolean) => {
    setAdvancedOperationAluguel(value)
    if (advancedOperationAluguel) {
      advancedSearchOperation.push('2')
      return
    }

    if (!advancedOperationAluguel) {
      let arr = []
      arr = advancedSearchOperation.filter(a => a !== '2')
      setAdvancedSearchOperation(arr)
      return
    }
  }, [advancedOperationAluguel, advancedSearchOperation, setAdvancedSearchOperation, setAdvancedOperationAluguel])


  // const closeModal = () => {
  //   setAdvancedOperationAluguel(true)
  //   setAdvancedOperationLancamento(true)
  //   setAdvancedOperationVenda(true)
  //   setAdvancedSearchOperation([])
  //   setCardsCategoriesSelect([])
  //   setCity('')
  //   setDistrict('')
  //   setState('')
  //   setComodoBathrooms(0)
  //   setComodoBedRooms(0)
  //   setComodoSuites(0)
  //   setComodoVagas(0)
  //   setRangerPriceRentFinal(0)
  //   setRangerPriceRentInitial(0)
  //   setRangerPriceSaleFinal(0)
  //   setRangerPriceSaleInitial(0)
  //   setTypeBuildsSelected([])
  //   setModal2Open(false)
  // }

  return (
    <>

        <ContentModal>

          <div className="objective" style={{borderBottom: 'none'}}>
            <div className='div-header-modal-advanced'>
              <h2>Buscar imóvel</h2>
            </div>

            <div className='content-buttons'>
              <button className={advancedOperationLacamento ? 'button-inactive' : 'button-active'} onClick={() => handleAdvancedLancamento(!advancedOperationLacamento)}>Lançamento</button>
              <button className={advancedOperationVenda ? 'button-inactive' : 'button-active'} onClick={() => handleAdvancedVenda(!advancedOperationVenda)}>Comprar</button>
              <button className={advancedOperationAluguel ? 'button-inactive' : 'button-active'} onClick={() => handleAdvancedAluguel(!advancedOperationAluguel)}>Alugar</button>
            </div>
          </div>

          <div className="box-input-ranger" style={{paddingTop: '10px', paddingBottom: '0px', borderBottom: 'none'}}>
            <h2>Faixa de preço</h2>

            <p style={{ marginBottom: '4px' }} >Venda</p>
            <div className="range" style={{marginBottom: '10px'}}>
              <input
                value={maskBRL(rangerPriceSaleInitial)}
                onChange={handleChangeSaleInicial}
              />

              <Slider className='range-slider' range max={1000000} defaultValue={[rangerPriceSaleInitial, rangerPriceSaleFinal]} value={[rangerPriceSaleInitial, rangerPriceSaleFinal]} onChange={(e) => { setRangerPriceSaleInitial(e[0]); setRangerPriceSaleFinal(e[1]) }} />

              <input
                value={maskBRL(rangerPriceSaleFinal)}
                onChange={handleChangeSaleFinal}
              />
            </div>

            <p style={{ marginBottom: '4px' }} >Aluguel</p>
            <div className="range" style={{}}>
              <input
                value={maskBRL(rangerPriceRentInitial)}
                onChange={handleChangeRentInicial}
              />

              <Slider className='range-slider' range max={10000} defaultValue={[rangerPriceRentInitial, rangerPriceRentFinal]} value={[rangerPriceRentInitial, rangerPriceRentFinal]} onChange={(e) => { setRangerPriceRentInitial(e[0]); setRangerPriceRentFinal(e[1]) }} />

              <input
                value={maskBRL(rangerPriceRentFinal)}
                onChange={handleChangeRentFinal}
              />
            </div>
          </div>

          <div className="categories" style={{paddingTop: '20px', borderBottom: 'none'}}>
            <h2>Categorias</h2>

            <div className="box-cat-single">
              {
                categorys?.map(cat => (
                  <CardCategorie
                    key={cat.id}
                    label={cat.titulo}
                    checked={cardsCategoriesSelect}
                    id={cat.id}
                    setCheck={setCardsCategoriesSelect}
                  />
                ))
              }
            </div>
          </div>

          <div className='type' style={{borderBottom: 'none'}}>
            <h2 style={{marginTop: '0px'}} >Tipo</h2>
            <div className='select-type'>
              <ConfigProvider
                theme={{
                  components: {
                    Select: {
                      controlHeight: 5,
                      colorBgContainer: 'transparent',
                      colorBorder: 'transparent',
                      borderRadius: 0,
                      colorTextPlaceholder: '#888A88'
                    }
                  }
                }}
              >
                <Select defaultValue={[]} showArrow={false} maxTagTextLength={5} showSearch={false} bordered={false} style={{ width: '100%', backgroundColor: 'transparent' }} mode='multiple' allowClear placeholder='Selecione' options={typeOptions} className='select-type-select' onChange={handleChangeTypeBuilds} />
              </ConfigProvider>
            </div>
          </div>

          <div className='address' style={{borderBottom: 'none'}}>
            <h2>Onde?</h2>
            <GooglePlacesAutocomplete
              apiKey={'AIzaSyCDsc9pmM4xfjWcIyNokhgbrd5SYQDiz7o'}
              selectProps={{
                value: address,
                onChange: setAddress,
                className: 'google',
                styles: {
                  control: (provided, state) => ({
                    ...provided,
                    border: '1px solid rgba(0,0,0, .2)',
                    background: 'white',
                    height: '3rem',
                    borderRadius: '8px',
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    marginLeft: '0.1rem',
                    marginBottom: '1rem',
                    transition: 'all.3s',
                    '&:hover': {
                      background: 'rgba(0,0,0,.09)'
                    }
                  }),
                },
                placeholder: 'Digite o BAIRRO e a CIDADE desejada.',
                noOptionsMessage: () => ('Nenhum endereço encontrado')
              }}
            />
          </div>

          <div className="rooms">
                            <h3 style={{marginTop: '36px', fontWeight: '600'}} >Características</h3>


            <div className="room-single">
              <h4>Quartos</h4>
              <div className="bullets">
                <div className={`${comodoBedRooms === 0 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBedRooms(0)}>0</div>
                <div className={`${comodoBedRooms === 1 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBedRooms(1)}>1</div>
                <div className={`${comodoBedRooms === 2 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBedRooms(2)}>2</div>
                <div className={`${comodoBedRooms === 3 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBedRooms(3)}>3</div>
                <div className={`${comodoBedRooms === 4 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBedRooms(4)}>4</div>
                <div className={`${comodoBedRooms === 5 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBedRooms(5)}>5</div>
                <div className={`${comodoBedRooms === 6 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBedRooms(6)}>6+</div>
              </div>
            </div>
            <div className="room-single">
              <h4>Banheiros</h4>
              <div className="bullets">
                <div className={`${comodoBathrooms === 0 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBathrooms(0)}>0</div>
                <div className={`${comodoBathrooms === 1 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBathrooms(1)}>1</div>
                <div className={`${comodoBathrooms === 2 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBathrooms(2)}>2</div>
                <div className={`${comodoBathrooms === 3 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBathrooms(3)}>3</div>
                <div className={`${comodoBathrooms === 4 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBathrooms(4)}>4</div>
                <div className={`${comodoBathrooms === 5 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBathrooms(5)}>5</div>
                <div className={`${comodoBathrooms === 6 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoBathrooms(6)}>6+</div>
              </div>
            </div>
            <div className="room-single">
              <h4>Suites</h4>
              <div className="bullets">
                <div className={`${comodoSuites === 0 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoSuites(0)}>0</div>
                <div className={`${comodoSuites === 1 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoSuites(1)}>1</div>
                <div className={`${comodoSuites === 2 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoSuites(2)}>2</div>
                <div className={`${comodoSuites === 3 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoSuites(3)}>3</div>
                <div className={`${comodoSuites === 4 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoSuites(4)}>4</div>
                <div className={`${comodoSuites === 5 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoSuites(5)}>5</div>
                <div className={`${comodoSuites === 6 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoSuites(6)}>6+</div>
              </div>
            </div>
            <div className="room-single">
              <h4>Vagas</h4>
              <div className="bullets">
                <div className={`${comodoVagas === 0 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoVagas(0)}>0</div>
                <div className={`${comodoVagas === 1 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoVagas(1)}>1</div>
                <div className={`${comodoVagas === 2 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoVagas(2)}>2</div>
                <div className={`${comodoVagas === 3 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoVagas(3)}>3</div>
                <div className={`${comodoVagas === 4 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoVagas(4)}>4</div>
                <div className={`${comodoVagas === 5 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoVagas(5)}>5</div>
                <div className={`${comodoVagas === 6 ? 'bullet-single clicked' : 'bullet-single'}`} onClick={() => setComodoVagas(6)}>6+</div>
              </div>
            </div>
          </div>

        </ContentModal>

      <GoToTop />
    </>
  );
}