import React, { useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { IArquivo } from '../../types';
import { ShowPhotoLargue } from '../ShowPhotoLarge';
import { Container } from "./styles";

interface IProps {
  isOpen?: boolean;
  files: IArquivo[];
  onClose: () => void;
}

export function ShowAllPhotos({ isOpen, files, onClose }: IProps) {

  const [viewPhotoFull, setViewPhotoFull] = useState(false);
  // const [fileFull, setFileFull] = useState('');
  const [index, setIndex] = useState<number>();

  const handlePhotoFull = (file: any) => {
    setViewPhotoFull(true)
    setIndex(file)
  }

  const closePhotoFull = () => {
    setViewPhotoFull(false)
  }

  return (
    <>

      <Container >

        {
          viewPhotoFull &&
          <ShowPhotoLargue
            files={files}
            isOpen={viewPhotoFull}
            onClose={closePhotoFull}
            index={index}
          />
        }

        <div className="header" style={{display: "flex", alignItems: "center", justifyContent: 'center'}}>
          <BiChevronLeft
            size={32}
            onClick={onClose}
          />
        </div>

        <div className="all-photos">
          {files.map((file, index) => (
            <div className="img">
              <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png";
              }} src={file.url} alt="Imagens Nogueira" onClick={() => handlePhotoFull(index)} />
            </div>
          ))}
        </div>

      </Container>
      
    </>
  );
}