import { SiCheckmarx } from "react-icons/si";

interface Props {
    categories: [{
        id: number;
        titulo: string;
        tags: [
            {
                selected: string;
                id_tag: number;
                titulo: string;
                id_categoria: number;
                url_icon: string;
            }
        ]
    }];
}

interface category{
    id: number;
        titulo: string;
        tags: [
            {
                selected: string;
                id_tag: number;
                titulo: string;
                id_categoria: number;
                url_icon: string;
            }
        ]
}

function checkHasTag(category: category|category[]){
    if(!Array.isArray(category)) {
        return category.tags.some(tag => tag.selected === 'checked');
    } else {
        for (let cat of category) {
            if(cat.tags.some(tag => tag.selected === 'checked')) return true;
        }
    }
}

export function CategoryArea({ categories }: Props) {
    if (!categories) return null; // Retorna null se não houver categorias
    return (
        <>
            {checkHasTag(categories) ? <h3 style={{ marginTop: '36px', fontWeight: '600' }} >Características</h3> : null} {/* Renderiza o título apenas se houver tags selecionadas */}
            {categories.map((cat) => {
                if (checkHasTag(cat)) return (<div key={cat.id}> {/* Garantindo a chave única para cada categoria */}
                    <h4>{cat?.titulo}</h4>
                    <div className="titles-tags">
                        {cat?.tags?.map((tag) => {
                            if (tag.selected === 'checked') {
                                return (
                                    <div className="infos" key={tag.id_tag}> {/* Garantindo a chave única para cada tag */}
                                        {tag.url_icon ? (<img className='icon-tag' src={tag.url_icon} alt='' style={{maxWidth: '17px', maxHeight: '17px' }}></img>):(<SiCheckmarx className='icon-tag'/>)}
                                        <p>{tag?.titulo}</p>
                                    </div>
                                );
                            }
                            return null; // Retorna null em vez de um fragmento vazio para evitar renderizações desnecessárias
                        })}
                    </div>
                </div>)
                else return null;
            })}
        </>
    );
}
