import styled from "styled-components";

interface PropsShow {
    show: boolean;
}

export const Container = styled.div<PropsShow>`
    width: 100%;
    height: 100vh;
    overflow-y: auto;

    .capa-empreendimento {
      position: relative;

      img{
        width: 100%;
        height: 500px;
        object-fit:cover;
        box-shadow: 0px 2px 4px rgba(0,0,0, .1);
        
        @media screen and (max-width:900px){
          height: 40vh;
        }
        @media screen and (max-width:720px){
          height: 20vh;
        }

      }
      
      .lancamento {
        margin: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom: 0.1rem;
        right: 1rem;

        .text-lancamento{
          font-weight: 700;
          text-align: right;
          background-color: var(--secondary-blue);
          padding: 0.2rem 0.7rem;
          border-radius: 0.5rem;
          text-shadow: rgba(0,0,0,0.6) 0.1em 0.1em 0.2em;
          color:white;
        }
      }
    }

    
`;

export const Content = styled.div`
    width: 90vw;
    max-width: 1920px;
    /* padding: 2% 4%; */
    margin: 0 auto;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title-and-tags {
            display: flex;
            align-items: center;
            gap:1rem;
            margin: 1rem 0 1.3rem 0;

            h1 {
                font-weight: 500;
                margin: 0;
                @media screen and (max-width:800px){
                    font-size: 1.2rem;
                }
            }
            .exclusive {
                background-color: var(--background-button-component);
                padding: 0.2rem 0.5rem;
                display: flex;
                align-items: center;
                gap: 0.3rem;
                border-radius: 0.5rem;

                p{
                    color:white;
                    margin: 0;
                    font-weight: 700;
                    font-size: 0.7rem;
                }

                img{
                    width: 20px;
                    height: 20px;
                    border-radius: 0.25rem;
                }
            }
        }
        .status {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          border-radius: 0.5rem;
          font-weight: 400;
          padding: 0.3rem;
          color:var(--color-placeholder-input);
          border: 1px solid var(--color-placeholder-input);
          
          p{
            font-size: 0.8rem;
            margin: 0;
          }

          .icon-construction {
            font-size: 1rem;
          }
        }
        
    }

    

    .location {
        text-decoration: underline;
        font-weight: 400;
        font-size: 1rem;
        margin-top: 0;
        cursor: pointer;
        /* margin: 0.5rem; */
        color: var(--backgroud-button-component);

        @media screen and (max-width:800px){
            font-size: .8rem;
        }
    }
`;

export const Divisions = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom:4rem;
    margin-top:2rem;

    @media screen and (max-width:800px){
        margin-top:1rem;
        display: grid;
        grid-template-columns: 1fr;
    }
`;

export const Left = styled.div`
    width: 65%;
    padding-right: 1rem;

    @media screen and (max-width:800px){
        padding-right: 0;
        width: 100%;
    }

    h3 {
        font-weight: 600;
        margin-bottom: 1.8rem;
        color: var(--background-button-component);
    }

    .about-enterprise{
        color: #555;
        font-size: .9rem;
        margin-bottom: 1.5rem;
        line-height: 22px;
    }

    .location-enterprise {
        color: var(--background-button-component);
        font-weight: 300;
    }
`;

export const Right = styled.div<PropsShow>`
    width: 35%;
    @media screen and (max-width:600px){
      width: 100%;
    }
    

    .value-property {

        @media screen and (max-width:800px){
            display: none;
            
        }

        .sub {
            text-align: right;
            font-weight: 500;
            color: var(--color-title-home);
        }
        .value {
            text-align: right;
            font-size: 2.5rem;
            font-weight: 600;
            margin-bottom:1rem;
            line-height: 22px;
        }
        .traco {
            width: 100%;
            height: 1px;
            margin-top: 2rem;
            background-color: var(--background-footer);
        }
    }

    .financiavel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-top: 1rem;
        color: var(--background-button-component);

        p{
            margin:0;
        }

        .icon-bank {
            margin-bottom: 5px;
        }

        .icon-check {
            font-size: 1.2rem;
            margin-bottom: 5px;
        }
    }

    .descricao-pagamento {
        font-size: 0.8rem;
        line-height: 22px;
        margin-top: 0.5rem;
        text-align: right;
        color: var(--background-button-component);
    }

    

      .desc-right-wrapper {
      position: sticky;
      transition: 1s ease;
      top: ${({ show }) => show ? '1rem' : '6rem'};

      @media screen and (max-width:800px){
        /* margin-top: .3rem; */
        position: relative;
        top:1rem;
      }

      .announcement {
        margin: 1rem;

.broker-information {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* border: 1px solid red; */
  height: 100px;

  .image {
    width: 95px;
    height: 95px;
    border-radius: 50%;
    /* background-color: rgba(0,0,0, .1); */
    /* cursor: pointer; */

    img {
      width: 95px;
      height: 95px;
      object-fit: cover;
      border-radius: 50px;
    }

    .avatar {
      font-size: 95px;
      color: var(--background-button-component);
    }

  }

  .name {
    display: block !important;
    h2{
      margin:0;
      /* cursor: pointer; */
      color: var(--background-button-component);
    }

    p {
      color: #777;
      font-size: 0.8rem;
      margin:0.5rem 0 0 0;
      font-weight: 600;
    }

    .phone {
      color: var(--background-button-component);
      font-size: 1rem;
      display: flex;
      align-items: center;

      .avatar-phone {
        font-size: 1.5rem;
        margin: 0 0.2rem 0 0;
      }
    }
  } 
}
}
    }

    .desc-right-wrapper,
    .simulate-financing,
    .highlights {
      margin-top: 2rem;
      border-radius: 20px;
      border: 1px solid rgba(0,0,0, .1);
      box-shadow: 0px 2px 4px rgba(0,0,0, .09);
      
      h4 {
        text-align: center;
        margin-bottom: 1rem;
      }
    }

    .desc-right-wrapper .form,
    .simulate-financing .form {

        .div-calendar {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        border: none;

        .calendar {
          border: none;

          .react-calendar__tile--range {
            background-color: var(--background-button-component);
            
          }
          .react-calendar__tile--now {
            background: var(--gray-one);
            
          }
          .react-calendar__tile:enabled:hover{
            background-color: #e6e6e6;
            
          }
          .react-calendar__tile:enabled:focus {
            background-color: var(--background-button-component);
            
          }
        }
      }

      .div-button-calendar {
        display: flex;
        justify-content: flex-end;

        button {
          margin-right: 2rem;
          border: none;
          background-color: var(--background-button-component);
          padding: 0.5rem 1rem;
          color: white;
          font-size: 0.8rem;
          border-radius: 6px;
          cursor: pointer;
          transition: .3s;

          &:hover {
            filter: brightness(0.8);
          }
        }
      }

      input {
        width: 100%;
        height: 52px;
        padding-left: 1rem;
        margin-bottom: 2rem;
        border: 1px solid rgba(0,0,0, .09);
        border-radius: 6px;
        background-color: #f8f8f8;

        &::placeholder {
          color: #ABABAB;
        }
      }

      .text-and-check {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        margin: 0 0 1rem;
        
        p {
          font-size: 0.8rem;
          margin: 0;
          color: var(--color-font-footer);
        }
        .check {
          height: 15px;
          width: 15px;
          margin: 0 0 0 5px;
          border: 1px solid var(--color-font-footer);
          color: var(--background-button-component);

          input[checked] {
            background-color: var(--background-button-component);
          }
        }
      }
    }

    .desc-right-wrapper .form,
    .simulate-financing .form {
      padding: 1rem;

      .calendar {
        border: none;
        margin-bottom: 1rem;
        border-radius: 6px;
        /* background-color: #f8f8f8; */
        .react-calendar__tile--range {
            background-color: var(--background-button-component);
            
        }
        .react-calendar__tile--now {
          background: var(--gray-one);
          
        }
        .react-calendar__tile:enabled:hover{
          background-color: #e6e6e6;
          
        }
        .react-calendar__tile:enabled:focus {
          background-color: var(--background-button-component);
          
        }
      }

      textarea {
        width: 100%;
        min-height: 120px;
        padding-top: .8rem;
        padding-left: 1rem;
        margin-bottom: 0.8rem;
        border: 1px solid rgba(0,0,0, .09);
        border-radius: 6px;
        background-color: #f8f8f8;

        &::placeholder {
          color: #ABABAB;
        }
      }

      h4 {
        margin: 1rem 0 1.5rem 0 ;
      }

    }
    
    .highlights {
      padding: 0 1rem 1rem 1rem;
    }
    .highlights .highlight-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 220px;
      padding: .7rem;
      border-radius: 15px;
      background-color: rgba(0,0,0, .2);
      box-shadow: inset 0px -55px 32px rgba(0,0,0, .5); 

      .info-highlights {
        display: flex;
        gap: .5rem;

        .orange {
          background-color: #B15636;
        }
        .bold-green {
          background-color: #153B3E;
        }

        .premium, .launch {
          font-size: .8rem;
          color: #fff;
          padding: 5px 8px;
          border-radius: 4px;
        }
      }

      .informations-price {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        color: #fff;

        p {
          font-weight: 900;
        }
      }
    }

    
`;

export const BoxPhotos = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;

    @media screen and (max-width:600px){
      display: none;
    }

    .photo-enterprise {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 223px;
            object-fit: cover;
        }
    }
`;

export const BoxPhotosMobile = styled.div`
  display: none;
  img {
    width: 100%;
    object-fit: cover;
    height: 300px;
  }

  @media screen and (max-width:600px){
    display: flex;
  }
`;

export const BoxVideo = styled.div`
    width: 100%;

    iframe {
        width: 100%;
        height: 56.6vh;
        border-radius: 1rem;
        object-fit: cover;
    }
`;

export const Traco = styled.div`
    width: 100%;
    height: 1px;
    margin: 2rem 0; 
    background-color: var(--background-footer);
`;

export const BoxCharacteristics = styled.div`
    .titles-tags {
        display: flex;
        gap: 1rem;
        
        .infos {
            display: flex;
            gap: 0.5rem;
            .icon-tag{

            }

            p{
                font-weight: 300;
            }
        }
       
    }
`;

export const BoxLocation = styled.div`
    height: 500px;
    border-radius: 8px;
    background-color: rgba(0,0,0, .1);
`;

export const BoxProximity = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .content-icon-and-info{
        .icon-and-info{
            display: flex;
            gap: 0.5rem;
            padding: 0.5rem 0;
            align-items: center;

            img{
                width: 1rem;
                height: 1rem;
            }

            p{
                font-weight: 300;
                font-size: 0.9rem;
                margin: 0;
            }
        }
        .distance {
            font-size: 0.8rem;
            color:var(--color-font-footer);
        }
    }
    
`;

export const BoxGeralInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);

  @media screen and (max-width:600px){
    grid-template-columns: repeat(2,1fr);
  }

  .icon-and-info-geral {
    .icon-info {
      display: flex;
      align-items: center;
      gap: 0.3rem;

      p{
        font-weight: 600;
        margin: 6.5px 0 0 0 ;
      }

      .icon{
        font-size: 1.5rem;
      }
    }
    .info {
      font-size: 0.8rem;
      margin: 0.1rem 0 0 1.7rem;
    }
  }
`;

export const BoxInfoContructor = styled.div`

`;