import { useCallback, useEffect, useMemo, useState } from "react"
import { Bs1Circle, Bs1CircleFill, Bs2Circle, Bs2CircleFill, Bs3Circle, Bs3CircleFill, Bs4Circle, Bs4CircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import { Footer } from "../../components/Footer";
import { Header, ScrollProps } from "../../components/Header"
import { MapsGoogle } from "../../components/MapsGoogle";
import { MapsGoogleStreetView } from "../../components/MapsGoogleStreetView";
import { useAuth } from "../../context/auth";
import { correios } from "../../service/correios";
import { googleGeocode } from "../../service/googleGeocode";
import { IPropsCategoriaImovel, IPropsLocation } from "../../types";
import { maskCep, maskCurrency, maskNumber, maskPrice } from "../../utils/mask";
import { ButtonComponent, Container } from "./styles"
import Select from 'react-select';

// interface IPropsCategorySelect {
//     value: number;
//     label: string;
// }

const RegisterNewProperty = () => {

    const { user, api } = useAuth();

    const [venda, setVenda] = useState(false);
    const [aluguel, setAluguel] = useState(false);

    const [descricao, setDescricao] = useState('');
    const [valorVenda, setValorVenda] = useState('');
    const [valorAluguel, setValorAluguel] = useState('');
    const [periodo, setPeriodo] = useState('');
    const [areaTotal, setAreaTotal] = useState('');
    const [areaConstruida, setAreaConstruida] = useState('');
    const [qtdQuartos, setQtdQuartos] = useState('');
    const [qtdBanheiros, setQtdBanheiros] = useState('');
    const [qtdSuites, setQtdSuites] = useState('');
    const [sobre, setSobre] = useState('');

    const [categorias, setCategorias] = useState<IPropsCategoriaImovel[]>([])
    const [categorySelect, setCategorySelect] = useState<any>([]);

    const [isCondominio, setIsCondominio] = useState<any>('');

    const [numeroAp, setNumeroAp] = useState('');
    const [bloco, setBloco] = useState('');
    const [taxaCond, setTaxaCond] = useState('');
    const [ano, setAno] = useState('')

    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [ruaEdit, setRuaEdit] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('')
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');

    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');
    const [value, setValue] = useState(false);
    const [location, setLocation] = useState<IPropsLocation>({} as IPropsLocation)

    const [pass1, setPass1] = useState(false);
    const [pass2, setPass2] = useState(false);
    const [pass3, setPass3] = useState(false);
    const [pass4, setPass4] = useState(false);
    const [pass, setPass] = useState<number>(1);
    const [actualPass, setActualPass] = useState<number>(1)

    const loadCategories = useCallback(async () => {
        api.get('/portal/category').then(res => {
            setCategorias(res.data)
        }).catch(e => console.log(e)).finally()
    }, [api]);

    useEffect(() => { loadCategories() }, [loadCategories])

    const getAddress = useCallback(async () => {
        if (cep.length === 8) {
            await correios.get(`/${cep}/json/`)
                .then((res) => {
                    if (res.data.erro !== 'true') {
                        console.log(res.data)
                        setRua(res.data.logradouro)
                        setBairro(res.data.bairro)
                        setCidade(res.data.localidade)
                        setEstado(res.data.uf)
                        setRuaEdit(res.data.logradouro?.split(" ").join("+"))
                    } else {
                        return toast.error('CEP não encontrado', {
                            position: "top-right",
                            autoClose: 6000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored'
                        })
                    }
                })
        }
    }, [cep])

    useEffect(() => {
        if (cep.length === 8) {
            getAddress()
        }
    }, [cep, getAddress])

    const loadLatLong = useCallback(async () => {
        await googleGeocode.get(`/json?address=${numero}+${ruaEdit},+${bairro},+${cidade},+${estado},+BR&key=${'AIzaSyCDsc9pmM4xfjWcIyNokhgbrd5SYQDiz7o'}`)
            .then((res) => {
                setLat(res.data.results[0].geometry.location.lat)
                setLng(res.data.results[0].geometry.location.lng)
            })
    }, [numero, ruaEdit, bairro, cidade, estado])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (numero !== '' && ruaEdit !== '' && bairro !== '' && cidade !== '' && estado !== '') {
                loadLatLong();
                setValue(true);
            }
        }, 2000)
        return () => clearTimeout(timer);
    }, [numero, ruaEdit, bairro, cidade, estado, loadLatLong])


    useEffect(() => {
        setRuaEdit(rua?.split(" ").join("+"))
    }, [rua])

    useEffect(() => {
        setLocation({ latCenter: lat, lngCenter: lng, latMarker: lat, lngMarker: lng, lat: lat, lng: lng })
    }, [lat, lng])

    function advancePass2() {
        // if (descricao === '') {
        //     return toast.error('Digite uma descrição sobre o imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if (!venda && !aluguel) {
        //     return toast.error('Defina se será venda ou aluguel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if (venda && valorVenda === '') {
        //     return toast.error('Defina o valor da venda', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if (aluguel && valorAluguel === '') {
        //     return toast.error('Defina o valor do aluguel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if (aluguel && periodo === '') {
        //     return toast.error('Defina o período do aluguel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(areaConstruida===''){
        //     return toast.error('Declare a área construída do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(areaTotal===''){
        //     return toast.error('Declare a área total do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(qtdBanheiros===''){
        //     return toast.error('Declare a quantidade de banheiros do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(qtdQuartos===''){
        //     return toast.error('Declare a quantidade de quartos do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(qtdSuites===''){
        //     return toast.error('Declare a quantidade de suítes', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(isCondominio && numeroAp===''){
        //     return toast.error('Digite o número do apartamento/casa', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(isCondominio && bloco===''){
        //     return toast.error('Digite o bloco', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(isCondominio && ano===''){
        //     return toast.error('Digite o ano do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(isCondominio && taxaCond===''){
        //     return toast.error('Informe a taxa do condomínio', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        setPass(2);
        setPass1(true);
        setActualPass(2);
    }

    useEffect(() => {
        if (!venda) {
            setValorVenda('')
        }
        if (!aluguel) {
            setValorAluguel('')
        }
    }, [aluguel, venda])

    function advancePass3() {
        // if (cep === '') {
        //     return toast.error('Digite o CEP do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(rua==='') {
        //     return toast.error('Digite o nome da rua do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(bairro===''){
        //     return toast.error('Digite o bairro do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(cidade===''){
        //     return toast.error('Digite a cidade do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(estado===''){
        //     return toast.error('Digite o Estado onde fica o imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        // if(numero===''){
        //     return toast.error('Informe o número do imóvel', {
        //         position: "top-right",
        //         autoClose: 6000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //         theme: 'colored'
        //     })
        // }

        setPass(3);
        setPass2(true);
        setActualPass(3)
    }

    useEffect(() => {

    }, [venda, aluguel, valorAluguel, valorVenda, periodo, isCondominio, numeroAp, taxaCond, ano])

    const catValue = useMemo(() => {
        categorySelect.map((cat: { value: any; }) => {
            return cat.value
        })
    }, [categorySelect])

    const handleImovel = useCallback(async () => {
        await api.post('/portal/building', {
            descricao,
            is_venda: venda ? 1 : 0,
            is_aluguel: aluguel ? 1 : 0,
            valor_venda: valorVenda === '' ? 0 : Number(valorVenda),
            valor_aluguel: valorAluguel === '' ? 0 : Number(valorAluguel),
            periodo_aluguel: periodo === '' ? 0 : Number(periodo),
            sobre,
            area_total: Number(areaTotal),
            area_construida: Number(areaConstruida),
            qtd_quartos: Number(qtdQuartos),
            qtd_banheiros: Number(qtdBanheiros),
            id_construtura: 3,
            qtd_suites: Number(qtdSuites),
            is_condominio: isCondominio === '' ? 0 : Number(isCondominio),
            ap_numero: numeroAp === '' ? 0 : Number(numeroAp),
            ap_bloco: bloco,
            id_corretor: user.id_corretor,
            ap_taxa_condominio: taxaCond === '' ? 0 : Number(taxaCond),
            ano_construcao: ano === '' ? 0 : Number(ano),
            id_pessoa_created: user.id_pessoa,
            categorias: catValue
        }).then(async res => {
            await api.post('/portal/building/adress', {
                id_imovel: `${res.data.id_imovel}`,
                cep,
                logradouro: rua,
                numero,
                bairro,
                cidade,
                estado,
                uf: estado,
                id_pessoa_created: user.id_pessoa,
                latitude: lat,
                longitude: lng
            }).then(res => {
                toast.success('Endereço do imóvel cadastrado com sucesso', {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored'
                })
                setPass4(true)

            }).catch(e => {
                console.log(e)
                toast.error('Não foi cadastrar endereço do imóvel', {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored'
                })
                toast.warning(`${e.response.data.err}`, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored'
                })
            })
        }).catch(e => {
            console.log(e)
            toast.error('Não foi possível cadastrar o imóvel', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
            toast.warning(`${e.response.data.err}`, {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        }).finally()
    }, [api, descricao, venda, aluguel, valorVenda, valorAluguel, periodo, sobre, areaTotal, areaConstruida, qtdQuartos, qtdBanheiros, qtdSuites, isCondominio, numeroAp, bloco, user.id_corretor, user.id_pessoa, taxaCond, ano, catValue, cep, rua, numero, bairro, cidade, estado, lat, lng])

    // useEffect(() => { console.log(categorySelect) }, [categorySelect])

    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    useEffect(() => { console.log(scrollTop) }, [scrollTop])

    return (
        <Container onScroll={handleScroll}>
            <Header show={show} setShow={setShow} visibleFilter={false} scroll={scrollTop} />
            <div className="content">

                <h2>Cadastrar imóvel (EM DESENVOLVIMENTO)</h2>

                <div className="content-pass">
                    {
                        pass1 ?
                            <Bs1CircleFill fontSize={'5rem'} color='var(--primary-green)' /> :
                            (actualPass === 1 ? <Bs1CircleFill fontSize={'5rem'} color='var(--background-button-component)' /> : <Bs1Circle fontSize={'5rem'} />)
                    }
                    <div className="traco" />
                    {
                        pass2 ?
                            <Bs2CircleFill fontSize={'5rem'} color='var(--primary-green)' /> :
                            (actualPass === 2 ? <Bs2CircleFill fontSize={'5rem'} color='var(--background-button-component)' /> : <Bs2Circle fontSize={'5rem'} />)
                    }
                    <div className="traco" />
                    {
                        pass3 ?
                            <Bs3CircleFill fontSize={'5rem'} color='var(--primary-green)' /> :
                            (actualPass === 3 ? <Bs3CircleFill fontSize={'5rem'} color='var(--background-button-component)' /> : <Bs3Circle fontSize={'5rem'} />)
                    }
                    <div className="traco" />
                    {
                        pass4 ?
                            <Bs4CircleFill fontSize={'5rem'} color='var(--primary-green)' /> :
                            (actualPass === 4 ? <Bs4CircleFill fontSize={'5rem'} color='var(--background-button-component)' /> : <Bs4Circle fontSize={'5rem'} />)
                    }
                </div>

                {
                    pass === 1 &&
                    <>
                        <h4>Informações do imóvel</h4>
                        {/* <BsBuilding />
                        <BsHouse /> */}
                        <div className="label-input-desc">
                            <p>Descrição</p>
                            <input value={descricao} onChange={(e) => setDescricao(e.target.value)} type="text" />
                        </div>

                        <div className="content-inputs">
                            <div className="left">

                                <div className="div-buttons">
                                    <ButtonComponent active={venda} className="button-1" onClick={() => setVenda(!venda)}>Vender</ButtonComponent>
                                    <ButtonComponent active={aluguel} className="button-2" onClick={() => setAluguel(!aluguel)} >Alugar</ButtonComponent>
                                </div>
                                {
                                    aluguel &&
                                    <div className="label-input">
                                        <p>Período</p>
                                        <select value={periodo} onChange={(e) => setPeriodo(e.target.value)}>
                                            <option value={''} >Selecione</option>
                                            <option value={'1'}>dia</option>
                                            <option value={'2'}>semana</option>
                                            <option value={'3'}>mês</option>
                                            <option value={'4'}>ano</option>
                                        </select>
                                    </div>
                                }

                                <div className="label-input">
                                    <p>Área construída (m²)</p>
                                    <input value={maskNumber(areaConstruida)} type="text" onChange={(e) => setAreaConstruida(e.target.value)} />
                                </div>
                                <div className="label-input">
                                    <p>Categoria</p>
                                    <Select
                                        placeholder='Selecione'
                                        isMulti
                                        isSearchable={false}
                                        onChange={(e) => setCategorySelect(e)}
                                        options={categorias?.map(cat => {
                                            return { value: cat?.id, label: cat?.titulo }
                                        })}
                                        styles={{
                                            container: (base) => ({
                                                ...base,
                                                width: '100%',
                                                height: '40px',
                                                border: 'none',
                                                ":focus": { border: '0' },
                                                "::selection": { border: '0' }
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                borderRadius: '5px',
                                                backgroundColor: 'var(--background-input)',
                                                width: '100%',
                                                height: '40px',
                                                border: '1px solid var(--background-footer)',
                                                ":focus": { border: '0' },
                                                ":hover": { border: '1px solid var(--background-footer)' },
                                                "::selection": { border: '0' },
                                                boxShadow: 'none'
                                            }),
                                            input: (base) => ({
                                                ...base,
                                                height: '40px',
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            valueContainer: (base) => ({
                                                ...base,
                                                height: '40px',
                                                paddingTop: '5px',
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            placeholder: (base) => ({
                                                ...base,
                                                height: '30px'
                                            }),
                                            option: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            dropdownIndicator: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            })
                                        }}
                                    />
                                </div>
                                <div className="label-input">
                                    <p>Quantidade de suítes</p>
                                    <input value={maskNumber(qtdSuites)} maxLength={2} onChange={(e) => setQtdSuites(e.target.value)} type="text" />
                                </div>

                                {
                                    isCondominio === '1' &&
                                    <>
                                        <div className="label-input">
                                            <p>Bloco</p>
                                            <input value={bloco} onChange={(e) => setBloco(e.target.value)} type="text" />
                                        </div>
                                        <div className="label-input">
                                            <p>Ano de construção</p>
                                            <input value={maskNumber(ano)} type="text" maxLength={4} onChange={(e) => setAno(e.target.value)} />
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="right">

                                <div className="values">
                                    <div className="label-input" >
                                        <p>Valor venda</p>
                                        <input disabled={!venda} value={maskCurrency(valorVenda)} onChange={(e) => setValorVenda(maskPrice(e.target.value))} type="text" />
                                    </div>
                                    <div className="label-input">
                                        <p>Valor aluguel</p>
                                        <input disabled={!aluguel} value={maskCurrency(valorAluguel)} onChange={(e) => setValorAluguel(maskPrice(e.target.value))} type="text" />
                                    </div>
                                </div>
                                <div className="label-input" >
                                    <p>Área total (m²)</p>
                                    <input value={maskNumber(areaTotal)} onChange={(e) => setAreaTotal(e.target.value)} type="text" />
                                </div>
                                <div className="values">
                                    <div className="label-input">
                                        <p>Quantidade de banheiros</p>
                                        <input value={maskNumber(qtdBanheiros)} maxLength={2} type="text" onChange={(e) => setQtdBanheiros(e.target.value)} />
                                    </div>
                                    <div className="label-input">
                                        <p>Quantidade de quartos</p>
                                        <input value={maskNumber(qtdQuartos)} maxLength={2} onChange={(e) => setQtdQuartos(e.target.value)} type="text" />
                                    </div>
                                </div>
                                <div className="label-input">
                                    <p>É condomínio?</p>
                                    <Select
                                        placeholder='Selecione'
                                        isMulti={false}
                                        isSearchable={false}
                                        onChange={(e) => setIsCondominio(e?.value)}
                                        options={[
                                            { value: '0', label: 'Não' },
                                            { value: '1', label: 'Sim' }
                                        ]}
                                        styles={{
                                            container: (base) => ({
                                                ...base,
                                                width: '100%',
                                                height: '40px',
                                                border: 'none',
                                                ":focus": { border: '0' },
                                                "::selection": { border: '0' }
                                            }),
                                            control: (base) => ({
                                                ...base,
                                                borderRadius: '5px',
                                                backgroundColor: 'var(--background-input)',
                                                width: '100%',
                                                height: '40px',
                                                border: '1px solid var(--background-footer)',
                                                ":focus": { border: '0' },
                                                ":hover": { border: '1px solid var(--background-footer)' },
                                                "::selection": { border: '0' },
                                                boxShadow: 'none'
                                            }),
                                            input: (base) => ({
                                                ...base,
                                                height: '40px',
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            valueContainer: (base) => ({
                                                ...base,
                                                height: '40px',
                                                paddingTop: '5px',
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            placeholder: (base) => ({
                                                ...base,
                                                height: '30px'
                                            }),
                                            option: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            menu: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            }),
                                            singleValue: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' },
                                                height: '30px'
                                            }),
                                            dropdownIndicator: (base) => ({
                                                ...base,
                                                ":focus": { border: 'none' },
                                                "::selection": { border: 'none' }
                                            })
                                        }}
                                    />
                                    {/* <select value={isCondominio} onChange={(e) => setIsCondominio(e.target.value)} >
                                        <option value={'0'}>não</option>
                                        <option value={'1'}>sim</option>
                                    </select> */}
                                </div>
                                {
                                    isCondominio === '1' &&
                                    <>
                                        <div className="label-input">
                                            <p>Número do apartamento</p>
                                            <input value={maskNumber(numeroAp)} onChange={(e) => setNumeroAp(e.target.value)} type="text" />
                                        </div>
                                        <div className="label-input">
                                            <p>Valor taxa do condomínio</p>
                                            <input value={maskCurrency(taxaCond)} onChange={(e) => setTaxaCond(maskPrice(e.target.value))} type="text" />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="text-area">
                            <p>Sobre o imóvel</p>
                            <textarea value={sobre} onChange={(e) => setSobre(e.target.value)} />
                        </div>
                        <div className="content-buttons-back-advance">
                            <button
                                className="advance"
                                onClick={advancePass2}
                            >
                                Avançar
                            </button>
                        </div>
                    </>
                }

                {
                    pass === 2 &&
                    <>
                        <h4>Endereço do imóvel</h4>
                        <div className="content-endereco">
                            <div className="left-endereco">
                                <div className="label-input" >
                                    <p>CEP</p>
                                    <input value={maskCep(cep)} maxLength={8} onChange={(e) => setCep(e.target.value)} type="text" />
                                </div>
                                <div className="number-complement">
                                    <div className="label-input" >
                                        <p>Número</p>
                                        <input value={numero} onChange={(e) => setNumero(e.target.value)} type="text" />
                                    </div>
                                    <div className="label-input" >
                                        <p>Complemento (Apt, bloc, etc)</p>
                                        <input value={complemento} onChange={(e) => setComplemento(e.target.value)} type="text" />
                                    </div>
                                </div>
                                <div className="label-input" >
                                    <p>Cidade</p>
                                    <input value={cidade} onChange={(e) => setCidade(e.target.value)} type="text" />
                                </div>
                            </div>
                            <div className="right-endereco">
                                <div className="label-input" >
                                    <p>Rua</p>
                                    <input value={rua} onChange={(e) => setRua(e.target.value)} type="text" />
                                </div>
                                <div className="label-input" >
                                    <p>Bairro</p>
                                    <input value={bairro} onChange={(e) => setBairro(e.target.value)} type="text" />
                                </div>
                                <div className="label-input" >
                                    <p>Estado</p>
                                    <input value={estado} onChange={(e) => setEstado(e.target.value)} type="text" />
                                </div>
                            </div>
                        </div>

                        {
                            value ?
                                <div className="content-map">
                                    <MapsGoogle height="300px" position={location} setPosition={setLocation} zoom={16} />
                                </div>
                                :
                                <div className="content-map-placeholder">
                                    <p>Digite o endereço do imóvel acima para ele aparecer no mapa</p>
                                    <img src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/5f15ea864c5d735f0139-map-placeholder.png'} alt="PlaceHolderMap" />
                                </div>
                        }


                        <div className="content-buttons-back-advance">
                            <button
                                className="back"
                                onClick={() => { setPass(1); setPass1(false); setActualPass(1) }}
                            >
                                Voltar
                            </button>
                            <button
                                className="advance"
                                onClick={advancePass3}
                            >
                                Avançar
                            </button>
                        </div>
                    </>
                }

                {
                    pass === 3 &&
                    <>
                        <h4>O endereço no marcador do mapa está correto?</h4>
                        <div className="content-maps-conference">
                            <MapsGoogle height="500px" position={location} setPosition={setLocation} zoom={16} />
                            <MapsGoogleStreetView height="500px" position={location} setPosition={setLocation} zoom={16} />
                        </div>
                        <h4 className="subtitle-maps">Caso não esteja no lugar correto, é só puxar marcador no mapa esquerdo para o lugar correto. Se estiver certo, é só clicar em avançar</h4>
                        <div className="content-buttons-back-advance">
                            <button
                                className="back"
                                onClick={() => { setPass(2); setPass2(false); setActualPass(2) }}
                            >
                                Voltar
                            </button>
                            <button
                                className="advance"
                                onClick={() => { setPass(4); setPass3(true); setActualPass(4) }}
                            >
                                Avançar
                            </button>
                        </div>
                    </>
                }

                {
                    pass === 4 &&
                    <>
                        <p>Página de upload de imagens</p>
                        <div>

                        </div>
                        <div className="content-buttons-back-advance">
                            <button
                                className="back"
                                onClick={() => { setPass(3); setPass3(false); setActualPass(3) }}
                            >
                                Voltar
                            </button>
                            <button
                                className="advance"
                                onClick={handleImovel}
                            >
                                Concluir
                            </button>
                        </div>
                    </>
                }

                {/* <div className="label-file">
                    <p>Foto do imóvel (escolha um ou mais arquivos)</p>
                    <input type='file' multiple />
                </div>

                <div className="label-textarea">
                    <p>Sobre o imóvel</p>
                    <textarea />
                </div>

                <div className="button-handle">
                    <button>Salvar imóvel</button>
                </div> */}
            </div>
            <Footer />
        </Container>
    )
}

export default RegisterNewProperty;