
import {
  Container,
} from './styles';

interface IPropButton {
    label: string;
    width?: string;
    height?: string;
    color?: string;
    bgcolor?: string;
    onclick?: () => void;
    mb?:string;
    disable?: boolean;
    style?: any;
    className?: any;
    children?: React.ReactNode;
}

export function Button({ label, width, height, color, bgcolor, onclick, mb, disable, style, className, children }: IPropButton){
  return(
    <Container 
        onClick={onclick} 
        w={width} 
        h={height} 
        color={color}
        bgcolor={bgcolor}
        mb={mb}
        style={style}
        className={className}
    >
        <button disabled={disable}>{label}{children}</button>
    </Container>
  );
}