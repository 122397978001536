import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Content, Divisions, Left, Right, BoxPhotos, Traco, BoxCharacteristics, BoxLocation, BoxProximity, BoxVideo, BoxGeralInfo, BoxInfoContructor, BoxPhotosMobile } from "./styles";
import { Header, ScrollProps } from '../../components/Header';
import { IPropertyDetailsCorrector, IPropsConstructors } from '../../types';
import { LoadSpinner2 } from "../../components/LoadSpinner2";
import { BsBank, BsCheckLg } from "react-icons/bs";
import { useJsApiLoader, GoogleMap, MarkerF } from "@react-google-maps/api";
import { Button } from "../../components/Button";
import { toast } from "react-toastify";
import Swal from 'sweetalert2' 
import withReactContent from 'sweetalert2-react-content'
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { MdLocalPhone } from "react-icons/md";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { maskPhone } from "../../utils/mask";
import validator from "validator";
import { LuConstruction } from 'react-icons/lu';
import { BiArea, BiMoneyWithdraw } from "react-icons/bi";
import { FaChartArea, FaRegCalendarAlt } from "react-icons/fa";
import { Carousel } from 'react-responsive-carousel';
import { ProximityBadge } from "../../components/ProximityBadge";
import { CategoryArea } from "../../components/CategoryArea";

const MySwal = withReactContent(Swal)

export function EnterpriseDetails() {

    const [scrollTop, setScrollTop] = useState<ScrollProps>({ y: 0, lastY: 0 } as ScrollProps);
    const [show, setShow] = useState(false);
    const [isTime, setIsTime] = useState(false);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);


    const { id } = useParams();
    const { api } = useAuth() 

    const [loadingContact, setLoadingContact] = useState(false)

    // const [descricao, setDescricao] = useState('');
    const [obs, setObs] = useState('');
    const [nomeCliente, setNomeCliente] = useState('');
    const [emailCliente, setEmailCliente] = useState('');
    const [contatoCliente1, setContatoCliente1] = useState('');
    const [horaReserva, setHoraReserva] = useState('')

    const [constructors, setConstructors] = useState<IPropsConstructors[]>([])

    const [dateReserve, setDateReserve] = useState<any>()
    const [dateFormat, setDateFormat] = useState<any>()

    // const [lat, setLat] = useState<number>(0);
    // const [long, setLong] = useState<number>(0);

    const [dataEnterprise, setDataEnterprise] = useState<IPropertyDetailsCorrector>({} as IPropertyDetailsCorrector);

    const loadData = useCallback(() => {
        setLoading(true)
        api.get(`/portal/building/${id}`).then(res => {
            setDataEnterprise(res.data[0])
        }).catch(e => console.log(e)).finally(() => setLoading(false))
    }, [api, id])

    const loadConstructors = useCallback(() => { 
        api.get('/portal/constructors').then(res => {
            setConstructors(res.data)
        }).catch(e => console.log(e)).finally()
    }, [api])

    useEffect(() => {
        loadData()
        loadConstructors()
    }, [loadConstructors, loadData])

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const scrollTop = event.currentTarget.scrollTop;
        setScrollTop(prevState => { return { y: scrollTop, lastY: prevState.y } })
    }

    useEffect(() => {
        let hora = ''
        if (dateReserve !== undefined) {
            hora = new Date(dateReserve).toISOString()
        }
        setDateFormat(`${hora.split('T')[0]}:00.000Z`)
    }, [horaReserva, dateReserve])

    const handleVisita = useCallback(async () => {

        if (nomeCliente === '') {
            return toast.error('Digite o seu nome', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        }

        if (contatoCliente1 === '') {
            return toast.error('Digite o número para contato', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        }

        if (emailCliente === '') {
            return toast.error('Informe um e-mail para contato', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        }

        if (!validator.isEmail(emailCliente)) {
            return toast.error('Digite um e-mail válido', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        }

        if (dateReserve === undefined) {
            return toast.error('Selecione uma data para visita', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        }

        if (isTime && horaReserva === '') {
            return toast.error('Selecione um horário para visita', {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored'
            })
        }

        setLoadingContact(true)
        MySwal.fire({
            title: 'Aguarde',
            text: 'Estamos salvando as informações',
            icon: 'warning',
            didOpen: () => {
                MySwal.showLoading()
            }
        })
        await api.post('/schedule/add-visita', {
            dt_inicio: horaReserva !== '' ? dateFormat : new Date(dateReserve).toISOString(),
            descricao: 'Agendamento de visita',
            observacao: obs,
            id_imovel: dataEnterprise.id,
            cliente_descricao: nomeCliente,
            cliente_email: emailCliente,
            cliente_telefone1: contatoCliente1,
            cliente_telefone2: contatoCliente1,
        }).then(res => {
            // setDescricao('')
            setObs('')
            setNomeCliente('')
            setEmailCliente('')
            setContatoCliente1('')
            setIsTime(false)
            setHoraReserva('');
            setDateReserve(new Date())
            MySwal.fire({
                icon: 'success',
                title: 'Sucesso!',
                text: 'O processo foi realizado com sucesso. Em breve a corretor retornará o seu contato.',
                confirmButtonColor: '#0E2244'
            })
        }).catch(e => {
            console.log(e)
            MySwal.fire({
                icon: 'error',
                title: 'Ops!',
                text: 'Não foi possível agendar sua visita.',
                confirmButtonColor: '#0E2244',
                footer: `${e.response.data.err}`
            })
        }).finally(() => setLoadingContact(false))
    }, [nomeCliente, contatoCliente1, emailCliente, dateReserve, isTime, horaReserva, api, dateFormat, obs, dataEnterprise.id])

    const imageDestaque = useMemo(() => {
        let img;
        dataEnterprise?.arquivos?.forEach(image => {
            if (image.is_destaque === 1) {
                img = image.url
            }
        })
        return img
    }, [dataEnterprise])

    const photosEnterpriseMemo = useMemo(() => {
        let ArrayImage = [0, 1, 2, 3]
        let ArrayResult: string[] = []

        let ArrayNoDestaq = dataEnterprise?.arquivos?.filter(img => img.is_destaque !== 1)

        ArrayImage.forEach((img, index) => {
            try {
                ArrayResult.push(ArrayNoDestaq[index].url)
            } catch (e) {
                ArrayResult.push('https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png')
            }
        })
        return ArrayResult
    }, [dataEnterprise])

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: 'AIzaSyCDsc9pmM4xfjWcIyNokhgbrd5SYQDiz7o'
    })

    // useEffect(() => {
    //     if (dataEnterprise.endereco !== undefined) {
    //         setLat(Number(dataEnterprise.endereco.latitude))
    //         setLong(Number(dataEnterprise.endereco.longitude))
    //     }
    // }, [dataEnterprise])

    const construtora = useMemo(() => {
        let cons;
        constructors?.forEach(constructor => {
            if (constructor.id === dataEnterprise.id_construtora) {
                cons = constructor.nome
            }
        })
        return cons
    }, [dataEnterprise, constructors])

    return (
        <Container onScroll={handleScroll} show={show}>
            <Header show={show} setShow={setShow} scroll={scrollTop} search={search} setSearch={setSearch} />
            {
                loading &&
                <LoadSpinner2 />
            }
            {
                !loading &&
                <>
                    <div className="capa-empreendimento">
                        <img src={imageDestaque} alt="Imagem capa do empreendimento" />
                        {
                            dataEnterprise?.is_lancamento === 1 &&
                            <div className="lancamento">
                                <p className="text-lancamento">
                                    LANÇAMENTO
                                </p>
                            </div>
                        }
                    </div>

                    <Content>
                        <div className="header">
                            <div className="title-and-tags">
                                <h1>
                                    {dataEnterprise?.descricao}
                                </h1>
                                {
                                    dataEnterprise?.is_exclusivo === 1 &&
                                    <div className="exclusive">
                                        <p>EXCLUSIVO</p>
                                        <img src="https://portal-nogueira.s3.us-east-2.amazonaws.com/6e5914b06c9cf6ac1dc5-logo-hub-capacete-white.png" alt="" />
                                    </div>
                                }
                            </div>
                            <div className="status">
                                {dataEnterprise.status !== null &&
                                    <>
                                        <p>
                                            {dataEnterprise?.status?.toLocaleUpperCase()}
                                        </p>
                                        <LuConstruction className='icon-construction' />
                                    </>
                                }
                            </div>
                        </div>
                        <Divisions>
                            <Left>
                                <h3>Descrição</h3>
                                <p className="about-enterprise">
                                    {dataEnterprise.sobre}
                                </p>
                                <Traco />
                                <h3>Fotos do empreendimento</h3>
                                <BoxPhotos>
                                    {
                                        dataEnterprise?.arquivos !== undefined &&
                                        <>
                                            {
                                                photosEnterpriseMemo.map((image, index) => {
                                                    return (
                                                        <div className='photo-enterprise'>
                                                            {
                                                                image !== undefined ?
                                                                    <img src={image} alt="Imagem Imóvel Nogueira" /*onClick={() => { setViewPhotoFull(true); setIndex(1) }}*/ />
                                                                    :
                                                                    <img src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/26848b2b91cbd520d366-placeholder-image-nogueira.png'} alt="Imagem Imóvel Nogueira" />
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </BoxPhotos>
                                <BoxPhotosMobile>
                                    <Carousel
                                        autoPlay={true}
                                        interval={5000}
                                        showThumbs={false}
                                        infiniteLoop={true}
                                        showStatus={false}
                                    // onClickItem={(index: number, item: any) => {}}
                                    >
                                        {
                                            dataEnterprise?.arquivos?.map((banner, index) => (
                                                // <Link key={banner.id} to={banner.url_link} target='_blank'>
                                                <img key={banner.id} onClick={() => { }} src={banner.url} alt={'banner do imóvel'} />
                                                // </Link>
                                            ))
                                        }
                                    </Carousel>
                                </BoxPhotosMobile>
                                {
                                    dataEnterprise.url_video !== '' &&
                                    <>
                                        <Traco />
                                        <h3>Vídeo do empreendimento</h3>
                                        <BoxVideo>
                                            <iframe width="560" height="315" src={`https://www.youtube.com/embed/${dataEnterprise?.url_video?.split('=')[1]}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        </BoxVideo>
                                    </>
                                }
                                <Traco />

                                <BoxCharacteristics>
                                    <CategoryArea categories={dataEnterprise?.categorias} />
                                </BoxCharacteristics>
                                <Traco />
                                <h3 style={{ marginTop: '36px', marginBottom: '16px' }}>Localização</h3>
                                <p className="location-enterprise">{`${dataEnterprise?.endereco?.logradouro}, Nº ${dataEnterprise?.endereco?.numero}, ${dataEnterprise?.endereco?.bairro}, ${dataEnterprise?.endereco?.cidade} - ${dataEnterprise?.endereco?.uf}`}</p>
                                <BoxLocation>
                                    {
                                        isLoaded ? (
                                            <GoogleMap
                                                mapContainerStyle={{ width: '100%', height: '100%' }}
                                                center={{
                                                    lat: Number(dataEnterprise?.endereco?.latitude),
                                                    lng: Number(dataEnterprise?.endereco?.longitude)
                                                }}
                                                zoom={14}
                                            >
                                                <MarkerF
                                                    position={{
                                                        lat: Number(dataEnterprise?.endereco?.latitude),
                                                        lng: Number(dataEnterprise?.endereco?.longitude),

                                                    }}
                                                    key={dataEnterprise.id}
                                                    visible={true}
                                                    zIndex={10000}
                                                // icon={mark}
                                                />
                                            </GoogleMap>
                                        ) :
                                            (
                                                <></>
                                            )
                                    }
                                </BoxLocation>
                                <h3 style={{ marginTop: '1rem', fontWeight: '600', marginBottom: '8px' }}>Proximidades</h3>
                                <BoxProximity>
                                    {
                                        dataEnterprise?.endereco?.proximidades?.map(pro => (
                                            <ProximityBadge proximidade={pro} coordenadasImovel={{ lat: Number(dataEnterprise.endereco.latitude), long: Number(dataEnterprise.endereco.longitude) }} />
                                        ))
                                    }
                                </BoxProximity>
                                <Traco />
                                <h3>Informações gerais</h3>
                                <BoxGeralInfo>
                                    {
                                    dataEnterprise?.area_total && <div className="icon-and-info-geral">
                                         <div className="icon-info">
                                            <BiArea className="icon" />
                                            <p>Área total</p>
                                        </div>
                                        <p className="info">{(dataEnterprise?.area_total?.includes("M²"))?dataEnterprise?.area_total:`${dataEnterprise?.area_total} M²`}</p>
                                    </div>
                                    }
                                    {
                                    dataEnterprise?.area_construida && <div className="icon-and-info-geral">
                                        <div className="icon-info">
                                            <FaChartArea className="icon" />
                                            <p>Área construída</p>
                                        </div>
                                        <p className="info">{(dataEnterprise?.area_construida?.includes("M²"))?dataEnterprise?.area_construida:`${dataEnterprise?.area_construida} M²`}</p>
                                    </div>
                                    }
                                    <div className="icon-and-info-geral">
                                        <div className="icon-info">
                                            <BiMoneyWithdraw className="icon" />
                                            <p>Valor do IPTU</p>
                                        </div>
                                        <p className="info">{dataEnterprise?.valor_iptu?(new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        }).format(dataEnterprise.valor_iptu)):"Não informado"}</p>
                                    </div>
                                    {
                                    dataEnterprise?.ano_construcao !== 0 ? <div className="icon-and-info-geral">
                                        <div className="icon-info">
                                            <FaRegCalendarAlt className="icon" />
                                            <p>Ano de conclusão</p>
                                        </div>
                                        <p className="info">{dataEnterprise?.ano_construcao}</p>
                                    </div> : null
                                    }
                                </BoxGeralInfo>
                                <Traco />
                                <h3>Construtora</h3>
                                <BoxInfoContructor>
                                    <p>{construtora}</p>
                                </BoxInfoContructor>
                            </Left>
                            <Right show={show}>

                                <div className='value-property'>
                                    <p className='sub'>Imóveis a partir de:</p>
                                    <p className='value'>{new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(dataEnterprise.valor_venda)}</p>
                                    <div className='traco' />
                                </div>
                                {
                                    dataEnterprise?.aceita_financiamento === 1 &&
                                    <div className="financiavel">
                                        <BsBank className="icon-bank" />
                                        <p>Financiável</p>
                                        <BsCheckLg className="icon-check" />
                                    </div>
                                }
                                {
                                    dataEnterprise.descricao_pagamento !== '' &&
                                    <p className="descricao-pagamento">{dataEnterprise.descricao_pagamento}</p>
                                }
                                <Traco />
                                <div className="desc-right-wrapper" >

                                    <div className="announcement">
                                        <div className="broker-information">
                                            <div className="image" ><img alt="imagem do corretor" src={'https://portal-nogueira.s3.us-east-2.amazonaws.com/4d4be3a754186d30b83a-logo-hub-nogueira-menor.png'} /></div>
                                            <div className="name">
                                                <h2 >Hub Nogueira</h2>
                                                <p>email@nogueira.com.br</p>
                                                <p className='phone'><MdLocalPhone className='avatar-phone' />(81)3019-3333</p>
                                            </div>
                                        </div>
                                    </div>

                                    <h4>Gostou do imóvel? Agende uma visita!</h4>
                                    <div className="form">

                                        <div className='div-calendar'>
                                            <Calendar
                                                className='calendar'
                                                minDate={new Date()}
                                                value={dateReserve}
                                                onChange={(v, e) => setDateReserve(v)}
                                                locale='pt-BR'

                                            />
                                        </div>

                                        <div className='text-and-check'>
                                            <p>Definir horário para visita?</p>
                                            <input className='check' type="checkbox" checked={isTime} disabled={loadingContact} onChange={(e) => setIsTime(e.target.checked)} />
                                        </div>

                                        {
                                            isTime &&
                                            <input type="time" value={horaReserva} disabled={loadingContact} onChange={(e) => setHoraReserva(e.target.value)} placeholder='Hora da reserva' />
                                        }

                                        <input type="text" value={nomeCliente} disabled={loadingContact} onChange={(e) => setNomeCliente(e.target.value)} placeholder='Nome Completo' />
                                        <input type="text" maxLength={11} disabled={loadingContact} value={maskPhone(contatoCliente1)} onChange={(e) => setContatoCliente1(e.target.value)} placeholder='DDD + Telefone' />
                                        {/* <input type="text" maxLength={11} value={maskPhone(contatoCliente2)} onChange={(e) => setContatoCliente2(e.target.value)} placeholder='Telefone 2' /> */}
                                        <input type="email" value={emailCliente} disabled={loadingContact} onChange={(e) => setEmailCliente(e.target.value)} placeholder='Digite seu e-mail' />
                                        {/* <input type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} placeholder='Nome do Imóvel *obrigatório' /> */}
                                        <textarea value={obs} disabled={loadingContact} onChange={(e) => setObs(e.target.value)} placeholder='Observação'></textarea>

                                        {/* <h4>Selecione uma data para visita:</h4> */}
                                        {/* <input type="date" /> */}

                                        {/* <h4>Seleciona a hora:</h4>
                                        <input type="time" /> */}

                                        <Button
                                            label={loadingContact ? 'Enviando' : 'Enviar'}
                                            width='100%'
                                            height='50px'
                                            color='#fff'
                                            bgcolor='#0F2445'
                                            onclick={handleVisita}
                                            mb='1rem'
                                            disable={loadingContact}
                                        />

                                    </div>
                                </div>
                            </Right>
                        </Divisions>
                    </Content>
                </>
            }
        </Container>
    )
}