import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2% 4%;
  background-color: #000;
  z-index: 65465742098765968776852426;
  /* overflow-y: auto; */

  

  & {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0, .3) transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 3px; /* A altura só é vista quando a rolagem é horizontal */
  }

  &::-webkit-scrollbar-track {
    background: rgba(0,0,0, .1);
    padding: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0, .3);
  }
  
  .all-photos {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: .5rem;
    margin-top: 2rem;
    padding-bottom: 4rem;
    border-radius: 1rem;
  }
  
  .all-photos  {
    border-radius: 1rem;
    background-color: rgba(0,0,0, .1);
    max-width: 1350px;
    
    .content-photo {
      display: flex;
      width: 100%;
      /* height: 690px; */
      position: relative;
      
      .image {
        width: 100%;
        max-height: 760px;
        border-radius: 1rem;
        object-fit: cover;
      }

        .icon {
          max-width: 35px;
          border-radius: 50%;
          background-color: rgba(0,0,0, .080);
          transition: all .2s ease;
          cursor: pointer;
          position: absolute;
          top:3%;
          left:1%;
          z-index: 65465742098765968776852426546479687654;
          border: 1px solid #888888;
          /* box-shadow: 10px 10px 8px 10px #888888; */
          
          &:hover {
            background-color: rgba(0,0,0, .098);
          }
        }
      
    }

    
  }

  .mbottom {
    margin-top: 4rem;
  }

  .div-imagem{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
    margin-left: auto;
    margin-right: auto;
    min-height: 30vh
  }

  .div-img {
    background-color: black; 
    border-radius: 15px;
    overflow-x: hidden;
    
  }

  .img-style {
    width: auto;
    height: screen;
    min-height: 93vh;
    margin-left: 20%;
    margin-right: 20%;
    max-width: 100%;
    object-fit: contain;
    border-radius: 15px
}


.button-close {
  position: absolute;
  width: fit-content;
  top: 1rem; 
  color: #ffffff;
  left: 93vw; 
  z-index: 30; 
  background: none;
  border: none;
}

/* @media (min-width: 768px) {
  .button-close {
    top: 2.25rem; 
    left: 90%;
  }
} */

.slick-arrow.slick-prev {
  color: #ffffff !important;
  z-index: 2000000 !important;
  left: 0 !important;
  top: 50% !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}

.carousel-imovel .slick-arrow.slick-prev::after {
  border-inline-width: 4px 0 !important;
  border-block-width: 4px 0 !important;
  width: 20px !important;
  height: 20px !important;
  position: static;

}

.carousel-imovel .slick-arrow.slick-next::after {
  border-inline-width: 4px 0 !important;
  border-block-width: 4px 0 !important;
  width: 20px !important;
  height: 20px !important;
  position: static;
}


  /* @media screen and (max-width: 1100px) {
    .header {
      margin-top: 2rem;
    }
    .all-photos {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 760px) {
    .all-photos {
      grid-template-columns: 1fr;
    }
  } */

`;