import '@splidejs/splide/dist/css/splide.min.css';
import { useCallback, useEffect, useState } from "react";
import { BsBuildingUp } from "react-icons/bs";
import { Container } from "./styles"
import { PropsConvenience } from '../../types';
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useAuth } from '../../context/auth';

interface IPropsResultCategory {
  setSearch?: (e: string) => void;
  handleSearch?: () => void;
}

const HeaderCategories = ({ setSearch, handleSearch }: IPropsResultCategory) => {

  const navigate = useNavigate();

  const { api } = useAuth()

  // const [active, setActive] = useState(1);

  // const handleActive = (num: number) => {
  //   setActive(num);
  // }

  const [convenience, setConvenience] = useState<PropsConvenience[]>([])

  const loadCategorys = useCallback(async () => {
    await api.get('/portal/convenience').then(res => {
      setConvenience(res.data)
    }).catch(e => console.log(e)).finally()
  }, [api])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { loadCategorys() }, [])

  const handleSearchFunction = useCallback((id: string) => {
    handleSearch?.()
    if (window.location.href !== `${process.env.REACT_APP_URL_PORTAL}/imovel/categoria`) {
      navigate(`/imovel/categoria/${id}`)
    }
  }, [handleSearch, navigate])

  return (
    <Container>
      <div className="filters-wrapper">
        <div className="filters-icons">
          <Carousel
            additionalTransfrom={0}
            arrows
            removeArrowOnDeviceType={['mobile','tablet']}
            autoPlay={true}
            autoPlaySpeed={3000}
            centerMode={false}
            className="carroussel-construcors"
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 10,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 768,
                  min: 0
                },
                items: 3,
                partialVisibilityGutter: 30,
                
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 768
                },
                items: 6,
                partialVisibilityGutter: 30
              }
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass="slide"
            slidesToSlide={2}
            swipeable
          >
            {
              convenience && convenience.map(cat => (
                // <SplideSlide>
                <div className='content-slide' key={cat.id}>
                  <div className='card'>
                    <div
                      onClick={() => { handleSearchFunction(`${cat.id}`); setSearch?.(`${cat.id}`) }}
                      className={'filter-single'}
                    >
                      {
                        (cat.url_icon !== '' || cat.url_icon !== null) ?
                        <img alt='' className='icon-convenience' src={cat.url_icon} />
                        :
                        <span><BsBuildingUp /></span>
                      }
                      <p className="name-filter">{cat.titulo}</p>
                    </div>
                  </div>
                </div>
                // </SplideSlide>
              ))
            }
          </Carousel>
          {/* </Splide> */}
        </div>
      </div >
    </Container >
  )
}

export default HeaderCategories